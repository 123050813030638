if (!console.debug) { // LiveAssist redefines console without a debug method
    console.debug = console.log;
}

(function() { // iife to avoid poluting the global namespace
    const PCPhone = require('./peer-connection.js');
    const aed = require('../common/aed.js');
    const utils = require('../common/utils.js');

    if (!window.UC) {
        throw("No CSDK Modules have been provided");
    }

    let started = false;
    let modules = window.UC.modules;
    let presence = window.UC.presence;

    //Define the message handlers
    function MessageHandler() {
        var messageHandlers = {};

        this.addHandler = function(name, handler) {
            if (!messageHandlers[name]) {
                messageHandlers[name] = handler;
            } else {
                throw("The message: " + name + " already has a handler!");
            }
        };

        this.handleMessage = function(message) {
            if (messageHandlers[message.type]) {
                messageHandlers[message.type](message);
            }
        };
        this.sendSwiftMessage = function(message) {
            if (!started) {
              throw "Cannot perform operation - Session not yet started"
            }
            var messageTxt = JSON.stringify(message);

            connection.send(messageTxt);
        };
    }

    function PingTimer()
    {
        this.pingInterval = null;
        var pingTimeout = null;
        var pingTimeOffset = 12000;

        this.startPingTimeout = function() {

            clearTimeout(pingTimeout);
            pingTimeout = null;

            if (this.pingInterval != null)
            {
                pingTimeout = setTimeout(function() {
                    if (!connection.isConnectionOffline()) {
                        console.log("No PING received from Gateway - reconnecting websocket");
                        connection.retryConnection();
                    }
                }, (this.pingInterval * 1000) + pingTimeOffset);
            }
        };
    }

    var pingTimer = new PingTimer();
    var messageHandler = new MessageHandler();
    const supportedBrowserVersions = {
      "chrome": "69",
      "firefox": "66",
      "safari": "604", // (=Safari 11) adapter reports AppleWebKit version
    };

    const initialise = function() {
      var jsonInitialise = {
          "type": "INITIALISE"
      };
      messageHandler.sendSwiftMessage(jsonInitialise);
    };

    const decodeSessionId = function(sessionID) {
      var unescaped = unescape(sessionID);

      var base = "/:?#[]@%$&'()*+,;=._~- ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
      var target = "!:?#[]@%$&'()*+,;=._~- 0987654321ZYXWVUTSRQPONMLKJIHGFEDCBAzyxwvutsrqponmlkjihgfedcba";

      var cb = [];
      for (var i = 0; i < unescaped.length; i++) {
          var pos = target.indexOf(unescaped[i]);
          cb[i] = base.charAt(pos);
      }
      return cb.join("");
    };

    /**
     * UC is the namespace to the CSDK WebRTC library.
     * It is used to access the component objects of the API, such the
     * the phone, presence, and AED instances.<br><br>
     *
     * The {@link UC#checkBrowserCompatibility} function provides a means for the application to ensure it has
     * the correct plugins installed (for those browsers that require one).<br><br>
     *
     * The {@link UC#start} function must be called for the connection to the server to
     * be created, and enabled components initialised.<br><br>
     *
     * There are a series of callbacks used to notify the user of UC changes. The onInitialised() callback indicates that
     * UC has finished initialising. The onInitialisedFailed() callback indicates that UC could not successfully initialise
     * all components. Various other callbacks are available for error indications.
     *
     * @namespace
     * @global
     */
    const UC = {
      /**
       * @readonly
       * @type AED
       */
      aed: {},

      /**
       * @readonly
       * @type Phone
       */
      phone: {
          setPreviewElement: function() { console.error("Cannot set preview element before UC.start is called"); }
      },

      /** 
       * @readonly
       * @type Background
       */
      background: {},

      /**
       * The detected user agent name
       *
       * <p>One of: "chrome", "firefox", "safari" or "Not a supported browser."</p>
       *
       * @readonly
       * @type {string}
       */
      detectedUserAgent: adapter.browserDetails.browser,

      /**
       * The detected user agent version
       *
       * @readonly
       * @type {string}
       */
      detectedUserAgentVersion: adapter.browserDetails.version,

      /**
       * The version number of this build
       * @readonly
       * @type {string}
       */
      csdkVersion: VERSION
    }

    /**
     * @callback UC.checkBrowserCompatibilityCb
     * @param {object} pluginInfo
     *              Information concerning the requirements and status of browser plugins
     * @param {boolean} pluginInfo.pluginRequired
     *              true - The browser requires a plugin for UC to operate correctly;<br>
     *              false - No browser plugin is required
     * @param {string} pluginInfo.status
     *              '' (zero length string) - When pluginInfo.pluginRequired is false;<br>
     *              ‘installRequired’ - The plugin is missing and a plugin install is required;<br>
     *              ‘upgradeRequired’ - A plugin is present but an upgrade of it is required;<br>
     *              ‘upgradeOptional’ - A valid plugin is installed but an upgrade is available;<br>
     *              ‘upToDate’ - The latest plugin is already installed;
     * @param {boolean} pluginInfo.restartRequired
     *              true - If an install is performed, the browser will require a restart;<br>
     *              false - If an install is performed, the browser will not require a restart (also when pluginInfo.pluginRequired is false);
     * @param {string} pluginInfo.installedVersion
     *              'none’ - When the plugin is missing (also when pluginInfo.pluginRequired is false);<br>
     *              ‘<x>.<y>.<z>’ - Where <x>, <y> and <z> are integers
     * @param {string} pluginInfo.minimumRequired
     *              'none’ - When the plugin is missing (also when pluginInfo.pluginRequired is false);<br>
     *              ‘<x>.<y>.<z>’ - Where <x>, <y> and <z> are integers. This is the minimum version of the plugin that is required for UC to operate correctly
     * @param {string} pluginInfo.latestAvailable
     *              'none’ - When the plugin is missing (also when pluginInfo.pluginRequired is false);<br>
     *              ‘<x>.<y>.<z>’ - Where <x>, <y> and <z> are integersThis is the latest version of the plugin available on the server, and the version addressed by pluginInfo.pluginUrl
     * @param {string} pluginInfo.pluginUrl
     *              '' (zero length string) - When pluginInfo.pluginRequired is false;<br>
     *              ‘<url>’ - Where <url> is the URL of the latest browser plugin
     * @param {object} browserInfo
     *              Information concerning the support for the current browser being used
     * @param {boolean} browserInfo.isSupported
     *              true - The browser is supported;<br>
     *              false - the browser is not supported (either unknown or unsupported version)
     * @param {string} browserInfo.name
     *              Lower case browser name;<br>
     *              e.g. "chrome", "firefox", "opera", "edge", "ie"
     * @param {string} browserInfo.version
     *              ‘<n>’ - Where <n> is an integer. This is the  major version of the browser.
     * @param {string} browserInfo.status
     *              '' (zero length string) - When browserInfo.isSupported is true;<br>
     *              ‘upgradeRequired’ - The browser is supported but below the minimum supported version;<br>
     *              ‘notSupported’ - The browser is not currently supported;
     * @param {string} browserInfo.minimumRequired
     *              '' (zero length string) - When the browser is not supported at all;<br>
     *              ‘<n>’ - Where <n> is an integer. This is the minimum major version of the browser that is required
     */

    /**
     * Determines the plugin needs and status of the browser.
     *
     * @param {UC.checkBrowserCompatibilityCb} checkBrowserCompatibilityCb - A callback that handles plugin information
     */
    UC.checkBrowserCompatibility = function(checkBrowserCompatibilityCb) {
        var browserName = UC.detectedUserAgent;
        var browserVersion = UC.detectedUserAgentVersion;

        var browserInfo = {
            isSupported: false,
            name: browserName,
            version: browserVersion,
            status: "unknown",
            minimumRequired: ""
        };

        if (supportedBrowserVersions.hasOwnProperty(browserName)) {
            browserInfo.minimumRequired = supportedBrowserVersions[browserName];
            if (parseInt(browserVersion) >= parseInt(browserInfo.minimumRequired)) {
                browserInfo.isSupported = true;
                browserInfo.status = "";
            } else {
                browserInfo.status = "upgradeRequired";
            }
        }

        console.log("Detected browser: " + browserName + " " + browserVersion +
            (browserInfo.isSupported ? " is supported" :
                (browserInfo.status === "upgradeRequired" ?
                    (" is not supported - needs upgrading to version " + browserInfo.minimumRequired + "+") :
                    " is not supported"
                )));

        checkBrowserCompatibilityCb(noPluginRequired(), browserInfo);
    }

    /**
     * Starts the system by completing the initialisation and creating instances of components such as phone and
     * presence. UC.start assumes the presence of a correct browser plugin (if required). If this is not the case an error may occur.
     *
     * @param {string} sessionId
     *            The session ID passed by the Gateway.
     * @param {string[]} [stunServers]
     *            Array of stun servers, no external servers will be used if not provided
     */
    UC.start = function(sessionId, stunServers) {
      started = true;
      console.log("received sessionId " + sessionId);
      var decodedSessionId = decodeSessionId(sessionId);
      console.log("Using URI " + decodedSessionId);

      url = decodedSessionId;

      var modulesStarted = startModules(stunServers);

      if (!modulesStarted) {
          console.error('Modules failed to start');
          throw 'Modules failed to start';
      }

      connection.connect(url);
      initialise();
    },

    /**
     * Stops the current FCSDK session on the client by closing the websocket connection
     */
    UC.stopSession = function() {
        console.log("received stopSession ");

        connection.disconnect();
        started = false;
    }

    /**
     * enable the camera effect features
     * @param {HTMLDivElement} elm Preview DIV element
     * @returns {Promise} a promise that resolves when UC.background has been populated
     */
    UC.background.enable = async function(elm) {
        if (!elm) return console.error("Please specify preview element as the first argument of UC.enableCameraEffects()");
        try {
            var handler = await import(/* webpackChunkName: "effects" */'./background/index.js');
            console.log("Handler is", handler);
            await handler.default(elm);
        } catch (e) {
            throw new Error("unable to load effects javascript")
        }
    }
    
    /**
     * @type {function(string)}
     */
    const emitUCEvent = utils.emitEvent.bind(null, UC);

    const noPluginRequired = function() {
      return {
          pluginRequired: false,
          status : '',
          restartRequired: false,
          installedVersion : 'none',
          minimumRequired: 'none',
          latestAvailable: 'none',
          pluginUrl: ''
      };
    }

    const startModules = function (stunServers) {
      if (UC.phone && stunServers) {
        console.log("using stunServers: ", stunServers);
        UC.phone.stunServers = stunServers;
      }

      // TODO: is this needed?
      if (presence !== undefined){
        UC.presence = presence;
        if (!UC.presence.start(messageHandler)) {
            return false;
        }
      }

      return true;
    }

    console.log("CSDK version: [" + UC.csdkVersion + "]");
    
    if (modules.includes('phone')) {
      console.log("Phone requested, adding");
      UC.phone = PCPhone(messageHandler, pingTimer);
    }

    if (modules.includes('aed')) {
      console.log("AED requested, adding");
      UC.aed = aed(messageHandler);
    }

    const handleInitialisationSuccess = function(jsonResult) {
      let phone = UC.phone;
      if (typeof(phone.initialisationSucessful) === 'function') {
          phone.initialisationSucessful(jsonResult);
      }
      emitUCEvent("Initialised");
    };

    messageHandler.addHandler("INITIALISATION_ERROR", () => emitUCEvent("InitialisedFailed"));
    messageHandler.addHandler("INITIALISATION_SUCCESS", handleInitialisationSuccess);
    messageHandler.addHandler("SYSTEM_FAILURE", () => emitUCEvent("SystemFailure"));
    
    window.UC = UC;

    var url;

    var multipart = "";
    var multipartID = 0;
    var processReceivedMessage = function(message) {
        if (message.type === "MULTIPART") {
            if (message.multipartID !== (multipartID + 1)) {
                throw ("Received out of order multipart message, cannot merge");
            }
            multipart = multipart + message.content;
            multipartID = message.multipartID;

            if (multipartID === message.finalMultipartID) {
                var content = multipart;
                multipartID = 0;
                multipart = "";
                console.log("Combined: " + content);
                receiveMessage(content);
            }
        } else {
            messageHandler.handleMessage(message);
        }
    };

    var receiveMessage = function(message) {
        var jsonResult = JSON.parse(message);

        if (jsonResult instanceof Array) {
            for (var i = 0; i < jsonResult.length; i++) {
                processReceivedMessage(jsonResult[i]);
            }
        } else {
            processReceivedMessage(jsonResult);
        }
    };
    messageHandler.receiveMessage = receiveMessage;
    var connection = new function() {
      var that = this;
      var webSocketOpen = false;
      var connectivityLost = false;
      var connectionClosed = false;
      var doNotReconnect = false;

      var reconnectAttempt = -1;
      // This constant controls the reconnection attempts. When the connection closes, up to RECONNECT_PERIODS.length
      // reconnection attempts will be made. Attempt k (1..n) will be made after delaying for RECONNECT_PERIODS[k-1] ms.
      var RECONNECT_PERIODS = [500, 1000, 2000, 4000, 4000, 4000, 4000, 5000, 5000, 5000, 5000, 5000, 5000, 6000,
          6000, 6000];

      var reconnecting = function(attempt, delayUntilNextAttempt) {
        emitUCEvent("ConnectionRetry", attempt, delayUntilNextAttempt);
      };

      var reconnected = function() {
        if (reconnectAttempt !== -1) {
          reconnectAttempt = -1;
          emitUCEvent("ConnectionReestablished");
        }
      };

      var reconnectError = function() {
          doNotReconnect = true;
          emitUCEvent("ConnectivityLost");
      };

      var onLocalConnectionLost = function() {
          emitUCEvent("NetworkUnavailable");
      };

      var connectivityTimeout = function() {
          if (!webSocketOpen) {
              console.log("Connectivity has been down for too long, not reconnecting");
              reconnectError();
          }
      };

      var onConnectivityLost = function() {
          var timeout = 20000;
          onLocalConnectionLost();
          that.disconnect();
          reconnectAttempt = 0;
          connectivityLost = true;
          setTimeout(connectivityTimeout, timeout);
          console.log("Reconnect will be attempted if connection returns in "+ timeout + "ms")
      };

      var onConnectivityGained = function() {
          var reconnectionDelay = 250;
          console.log("Connectivity has been restored, reconnecting in " + reconnectionDelay + " ms");
          connectivityLost = false;
          setTimeout(function() {
              if(doNotReconnect){
                  console.log("Reconnection has been abandoned");
                  return;
              }
              reconnecting(reconnectAttempt, reconnectionDelay);
              that.connect(url)
          }, reconnectionDelay);
      };

      window.addEventListener("offline", onConnectivityLost);
      window.addEventListener("online", onConnectivityGained);

      var webSocket = null;
      this.connect = function(toUrl) {
          console.log(new Date() + " Info: Opening WebSocket " + toUrl);
          if ('WebSocket' in window) {
              webSocket = new WebSocket(toUrl);
          } else {
              console.error("WebSockets are not supported by this browser.");
              emitUCEvent("InitialisedFailed");
              return;
          }
          connectionClosed = false;

          webSocket.onopen = function(/*ws*/) {
              console.log(new Date() + " Info: WebSocket connection opened.");
              reconnected();
              webSocketOpen = true;
              // TODO: This is a workaround for WEBRTC-2977 - It will refresh IM conversations
              if (window.UC.presence) {
                  var conversations = window.UC.presence.getConversations();
                  for (var i = 0; i < conversations.length; i++) {
                      var conversation = conversations[i];
                      conversation.start();
                  }
              }
          };
          webSocket.onerror = function(err){
              console.log(err);
          };
          webSocket.onmessage = function(event) {
              console.log('Received: ' + event.data);
              receiveMessage(event.data);
          };
          webSocket.onclose = function() {
              console.log(new Date() + " Info: WebSocket connection closed.");
              //Check this is actually the current websocket or one we created to replace it
              //If it isn't, then this is fired as part of the previous websocket's closing
              if (this===webSocket){
                  webSocketOpen = false;
                  webSocket = null;
              }else{
                  //In the case where this event is fired after the reconnect has started (since the websocket trails this event by 30s)
                  return;
              }
              if (connectivityLost || connectionClosed) {
                  console.debug("Close while already closed or no connectivity");
                  return;
              }
              reconnectAttempt++;
              if (reconnectAttempt >= RECONNECT_PERIODS.length) {
                  console.log(new Date() + " Info: WebSocket reconnection abandoned.");
                  reconnectError();
                  return;
              }
              var delay = RECONNECT_PERIODS[reconnectAttempt];
              console.log(new Date() + " Info: Attempting reconnect in " + delay + " ms");
              setTimeout(function() {
                  reconnecting(reconnectAttempt, delay);
                  that.connect(toUrl);
              }, delay);
          };
      };

      this.disconnect = function() {
          console.log('Info: WebSocket being destroyed');
          // hack to prevent re-connection attempts
          connectionClosed = true;

          if (webSocket != null) {
              webSocket.close();
              webSocket = null;
          }
          webSocketOpen = false;
      };

      this.retryConnection = function() {
          console.log("Retry connection");
          // this triggers reconnection algorithm
          if (webSocketOpen) {
              webSocket.onclose();
          }
      };

      this.isConnectionOffline = function() {
          return connectivityLost;
      };

      var sendMultipart = function(messageTxt) {
          var chunks = [];
          // A little less than the size checked for by the sender to make
          // sure we can actually send the parts after json-ing them
          var chunkSize = 500;

          while (messageTxt) {
              if (messageTxt.length < chunkSize) {
                  chunks.push(messageTxt);
                  break;
              } else {
                  chunks.push(messageTxt.substr(0, chunkSize));
                  messageTxt = messageTxt.substr(chunkSize);
              }
          }

          var totalParts = chunks.length;
          for (var i = 0; i < totalParts; i++) {
              var multipartMessage = {
                  "type": "MULTIPART",
                  "multipartID": (i + 1),
                  "finalMultipartID": totalParts,
                  "content": chunks[i]
              };
              messageHandler.sendSwiftMessage(multipartMessage);
          }
      };

      this.send = function(messageTxt) {
          if(doNotReconnect){
              console.log("Socket Down, reconnection has been abandoned");
              return;
          }
          if (!webSocketOpen) {
              //If the socket isnt open, we need to wait until it is, or Chrome fails
              console.log("Socket Down, queing message for sending");
              if (connectivityLost) {
                  setTimeout(function() {
                      that.send(messageTxt);
                  }, 1000);
              } else {
                  setTimeout(function() {
                      that.send(messageTxt);
                  }, 250);
              }
              return;
          }

          if (messageTxt.length > 700) {
              console.log("Splitting: " + messageTxt);
              sendMultipart(messageTxt);
          } else {
              console.log("Sending: " + messageTxt);
              webSocket.send(messageTxt);
          }
      };
  };
}());
