// @ts-check

/**
 * @param {HTMLElement} container
 * @param {boolean} video
 * @returns {HTMLMediaElement}
 */
function addMediaElement(container, video) {
  // Safai has issues using a video element with no video tracks 
  // so use audio in that case
  const tagName = video ? 'VIDEO' : 'AUDIO';
  
  let element = /** @type {HTMLMediaElement}*/ (container.firstElementChild);
  if (element && element.tagName == tagName) {
    return element;
  }
  removeMediaElement(container);

  element = /** @type {HTMLMediaElement}*/ (document.createElement(tagName));
  if (video) {
    element.setAttribute('playsinline', '');
  }

  console.debug("adding %s element to %s", tagName, container.id);
  container.appendChild(element);

  return element;
}

/**
 * @param {HTMLElement} container
 */
function removeMediaElement(container) {
  /** @type HTMLMediaElement */
  // @ts-ignore
  const element = container.firstElementChild;
  if (!element) return;

  element.srcObject = null;
  if (element.onloadedmetadata) {
    // remove listener
    element.onloadedmetadata = null;
  }
  console.debug("removing %s element from %s", element.tagName, container.id);
  container.removeChild(element); 
}

/**
 * @param {HTMLElement} element
 * @param {boolean} mirrorVideo
 */
function updateVideoStyle(element, mirrorVideo) {
  // @ts-ignore
  element.style = '';
  if (mirrorVideo) {
    // @ts-ignore
    element.style = 'transform: rotateY(180deg); -webkit-transform:rotateY(180deg); -moz-transform:rotateY(180deg);'
  }
  element.style.width = '100%';
  element.style.height = '100%';
}

/**
 * @param {MediaStream} stream
 */
function streamHasVideo(stream) {
  return stream.getVideoTracks().length > 0;
}

/**
 * factory function returning an object
 * does NOT require new XXX
 * this can either be used with the render method which requires the stream
 * to be set using setStream or with the renderStream method which
 * has the stream as a parameter.
 * @param {string} name for logging
 * @param {boolean} muteAudio
 * @param {Function} [videoResolutionListener]
 */
function MediaRenderer(name, muteAudio = false, videoResolutionListener) {
  const self = {};
  /** @type HTMLElement */
  let container;
  /** @type MediaStream */
  let mediaStream;

  const metaDataLoaded = function(event) {
    /** @type HTMLVideoElement */
    const mediaElement = event.target;
    if (videoResolutionListener) {
      videoResolutionListener(mediaElement.videoWidth, mediaElement.videoHeight);
    }
  }

  /**
   * sets the container element (a DIV)
   * @param {HTMLElement} element 
   */
  self.setContainer = function(element) {
    if (container) {
      removeMediaElement(container); // remove old children
    }
    container = element;
  }

  self.hasContainer = function() {
    return !!container;
  }

  /**
   * sets the media stream
   * @param {MediaStream} stream
   */
  self.setStream = function(stream) {
    mediaStream = stream;
  }

  self.render = function() {
    if (!mediaStream) {
      console.debug(name, 'Stream was not defined, not attaching stream');
      return;
    }
    self.renderStream(mediaStream, false);
  }

  /**
   * render the media stream
   * @param {MediaStream} stream
   * @param {any} mirrorVideo
   */
   self.renderStream = function(stream, mirrorVideo = false) {
    if (!container) {
      console.debug(name, 'Container element was not defined, not attaching stream');
      return;
    }

    const hasVideo = streamHasVideo(stream);
    const mediaElement = addMediaElement(container, hasVideo);
    if (hasVideo) {
      updateVideoStyle(mediaElement, mirrorVideo);
      mediaElement.onloadedmetadata = metaDataLoaded;
    }
    mediaElement.muted = !!muteAudio;
    if (mediaElement.srcObject == stream) {
      console.debug(name, "already playing stream");
      return; // already playing this stream in this container
    }
    mediaElement.srcObject = stream;
    mediaElement.play()
      .then(() => console.debug(name, container.id, "Play succeeded"))
      .catch((error) => console.debug(name, container.id, "Play failed: " + error));
  }

  self.close = function() {
    if (container) {
      removeMediaElement(container);
     }
  }

  return self;
}

module.exports = MediaRenderer;