const BFCP = require('./bfcp.js');
const LocalMediaGenerator = require('./local-media-generator-browser.js');
const MediaRenderer = require('./media-renderer.js');
const Statistics = require('./statistics.js');
const utils = require('../common/utils.js');

/**
 * create an RTCIceServer object
 * @param {string[]} urls list of server urls
 * @param {string} username the username
 * @param {string} credential the password
 * @returns {RTCIceServer}
 */
function createIceServer(urls, username, credential) {
  return {
    urls: urls,
    username: username,
    credential: credential
  };
}

/**
 * Object listing available media directions
 * @readonly
 * @enum {string}
*/
const mediaOptions = {
  enabled: 'enabled',
  onlyreceive: 'onlyreceive',
  disabled: 'disabled'
};

function mediaDirection(option) {
  // process argument to dial/answer method
  switch(option) {
    case true: // Backwards compatibility - true is enabled
    case mediaOptions.enabled:
      return {send: true, receive: true};
    
    case false: // Backwards compatibility - false is only receive
    case undefined: // Backwards compatibility - undefined is only receive
    case mediaOptions.onlyreceive:
      return {send: false, receive: true};

    case mediaOptions.disabled:
      return {send: false, receive: false};
    
    default:
      return {send: !!option, receive: true};
  }
}

/**
 * This factory method return the phone object is used to implement the Voice Enabled features 
 */
function PCPhone(messageHandler, pingTimer) {
    /**
     * @type LocalMediaGenerator
     * @private
     */
     var localMediaGenerator = new LocalMediaGenerator();

     /**
     * phone namespace object
     * functions added to this below
    */
    const phone = {
      stunServers: [], // may be updated in UC.start()
      media: mediaOptions,
      videoresolutions: localMediaGenerator.getAllowedVideoResolutions(),
      MirrorMode: { "Off": "Off", "Auto": "Auto", "On": "On" }
    };
    // Hold the current calls and their listeners
    const currentCalls = [];
    const callListeners = [];
    let todoWhenNoActiveCalls = [];

    /**
     * @private
     * @param call {PCCall} The call to remove
     */
    var removeCall = function (call) {
        console.log('removeCall ' + call);
        for (var i = 0; i < currentCalls.length; i++) {
            if (call === currentCalls[i]) {
                currentCalls.splice(i, 1);
                break;
            }
        }
        delete callListeners[call.getCallId()];

        if (currentCalls.length == 0) {
            for (var j = 0; j < todoWhenNoActiveCalls.length; j++) {
                todoWhenNoActiveCalls[j]();
            }
            todoWhenNoActiveCalls = [];
        }
    };

    var videoResolutionSendPreferences = "[x=176,y=144] [x=224,y=176] [x=272,y=224] [x=320,y=240] [x=640,y=480] [x=1280,y=720] [x=1920,y=1080]";
    var videoResolutionRecvPreferences = "[x=176,y=144] [x=224,y=176] [x=272,y=224] [x=320,y=240] [x=640,y=480] [x=1280,y=720] [x=1920,y=1080]";

    var previewElementMediaRequested = false;
    var retainMediaAfterCall = true;

    const localMediaRenderer = MediaRenderer("preview", true);
    var localMediaStream = null;
    var canvasMediaStream = null;

    /* Remember state of device mute */
    var audioEnabled = true;
    var videoEnabled = true;

    const emitPhoneEvent = utils.emitEvent.bind(null, phone);
    let mirrorModeSetting = "Off";
    let facingVideo = "user";
    
    phone.setAudioEnabled = function(enabled) {
        audioEnabled = enabled;
        console.log("PCPhone.setAudioEnabled " + audioEnabled);
    }

    phone.getAudioEnabled = function() {
        console.log("PCPhone.getAudioEnabled " + audioEnabled);
        return audioEnabled;
    }
    
    phone.setVideoEnabled = function(enabled) {
        videoEnabled = enabled;
        console.log("PCPhone.setVideoEnabled " + videoEnabled);
    }

    phone.getVideoEnabled = function() {
        console.log("PCPhone.getVideoEnabled " + videoEnabled);
        return videoEnabled;
    }

    phone.shouldMirrorVideo = function() {
      if (mirrorModeSetting == "On") {
        return true;
      }

      return mirrorModeSetting == "Auto" && facingVideo == "user";
    }

    var attachLocalMedia = function (stream) {
        localMediaStream = stream;

        if (!canvasMediaStream) {
            const canvasElement = document.createElement("canvas");

            var ctx = canvasElement.getContext("2d");
            ctx.beginPath();
            ctx.rect(0, 0, 1000, 1000);
            ctx.fillStyle = "black";
            ctx.fill();

            canvasElement.style.width = "100%";
            canvasElement.style.height = "100%";
            canvasElement.style.visibility = "hidden";

            canvasMediaStream = canvasElement.captureStream(25);
        }

        localMediaRenderer.renderStream(stream, phone.shouldMirrorVideo());

        emitPhoneEvent("LocalMediaStream", stream);
    };


    phone.setPreviewElement = function (element) {
        console.debug("Phone.setPreviewElement() with: [%s]", element);
        localMediaRenderer.setContainer(element);
        this.previewElement = element;
        if (localMediaStream) {
          localMediaRenderer.renderStream(localMediaStream, phone.shouldMirrorVideo());
        } else {
            console.debug("Requesting local media for preview");
            requestMediaForPreviewElement(true);
        }
    };

    phone.setPreferredVideoCaptureResolution = function (preferredResolution) {
        console.debug("setPreferredVideoCaptureResolution() with resolution: [" + preferredResolution + "]");

        if (currentCalls.length !== 0) {
            console.warn("setPreferedVideoCaptureResolution cannot be called while a call is ongoing, " +
                "this will be automatically triggered once all calls have ended");
            todoWhenNoActiveCalls.push(function () {
                phone.setPreferredVideoCaptureResolution(preferredResolution);
            });
            return;
        }

        if (preferredResolution
            && typeof (preferredResolution.width) === 'number'
            && typeof (preferredResolution.height) === 'number') {
            if (phone.getPreferredCaptureResolution().width !== preferredResolution.width
                || phone.getPreferredCaptureResolution().height !== preferredResolution.height) {
                localMediaGenerator.setPreferredVideoCaptureResolution(preferredResolution);
                var logWarning = function (error) {
                    console.warn("Could not start local capture: [" + error + "]");
                };

                localMediaGenerator.getVideoOnlyStream(attachLocalMedia, logWarning, true);
            } else {
                console.log("Resolution changed to the same value, not updating");
            }
        } else {
            throw "Could not set capture rate, width and height not specified";
        }
    };

    phone.setPreferredVideoFrameRate = function (preferredFrameRate) {
        console.debug("setPreferredVideoFrameRate() with: [" + preferredFrameRate + "]");

        if (currentCalls.length !== 0) {
            console.warn("setPreferredVideoFrameRate cannot be called while a call is ongoing, " +
                "this will be automatically triggered once all calls have ended");
            todoWhenNoActiveCalls.push(function () {
                phone.setPreferredVideoFrameRate(preferredFrameRate);
            });
            return;
        }

        if (phone.getPreferredCaptureFrameRate() !== preferredFrameRate) {
            localMediaGenerator.setPreferredVideoCaptureFrameRate(preferredFrameRate);
            var logWarning = function (error) {
                console.warn("Could not start local capture: [" + error + "]");
            };
            localMediaGenerator.getVideoOnlyStream(attachLocalMedia, logWarning, true);
        } else {
            console.log("Capture frame-rate changed to the same value, not updating");
        }
    };
    
    var changeDevice = function(audioDeviceId, videoDeviceId)
    {
        //change the device while a call is ongoing 
        const call = currentCalls[0];
        var isAudioEnabled = phone.getAudioEnabled();
        var isVideoEnabled = phone.getVideoEnabled();

        if(audioDeviceId != null){
            localMediaGenerator.setPreferredAudioInputId(audioDeviceId);
        }
        if(videoDeviceId != null){
            localMediaGenerator.setPreferredVideoInputId(videoDeviceId);
        }

        if (typeof(localMediaStream) !== "undefined" && localMediaStream != null) {
            localMediaGenerator.stopCapture();
            var onSuccess = function(stream){
                var videoTracks = stream.getVideoTracks();
                call.updateTrackForSending(videoTracks, stream);
                var audioTracks = stream.getAudioTracks();
                call.updateTrackForSending(audioTracks, stream);
                attachLocalMedia(stream);
            }
            var logWarning = function (error) {
                var userMediaMessage = "Could not get new user media, error was: [" + error + "]";
                console.warn("Could not get new user media: [" + error + "]");
                emitPhoneEvent("GetUserMediaError", userMediaMessage);
            };
            if(videoDeviceId != null){
                Promise.resolve()
                .then(function(){
                    return new Promise(function (resolve) {
                        call.getNewDevice(onSuccess, logWarning);
                        //apply mute state
                        call.setLocalMediaEnabled(isVideoEnabled, isAudioEnabled);
                        resolve();
                    });
                })
                .then(function(){
                    return new Promise(function (resolve) {
                        setTimeout(function() {
                        console.log('temp hold()');
                        call.hold();
                        resolve();
                        }, 50);
                    });
                })
                .then(function(){
                    return new Promise(function (resolve) {
                        setTimeout(function() {
                        call.resume();
                        console.log('temp resume()');
                        call.resume();
                        resolve();
                        }, 400);
                    });
                })
                .catch(function() {
                    console.log("Media Change failed");
                });
            } else {
                    call.getNewDevice(onSuccess, logWarning);
                    //apply mute state
                    call.setLocalMediaEnabled(isVideoEnabled, isAudioEnabled);
            }
        }
    }

    phone.setPreferredVideoInputId = function (id) {
        console.debug("setPreferredVideoInputId() to: [" + id + "]");

        if (currentCalls.length !== 0) {
            todoWhenNoActiveCalls.push(function () {
                phone.setPreferredVideoInputId(id);
            });
            changeDevice(null, id);
            return;
        }

        if (phone.getPreferredVideoInputId() !== id) {
            localMediaGenerator.setPreferredVideoInputId(id);
            var logWarning = function (error) {
                console.warn("Could not start local capture: [" + error + "]");
            };
            if (typeof (localMediaStream) !== "undefined" && localMediaStream != null) {
                localMediaGenerator.switchVideoOnlyStream(attachLocalMedia, logWarning, true);
                //localMediaGenerator.getVideoOnlyStream(attachLocalMedia, logWarning, true);
            }
        } else {
            console.log("Capture video input device id changed to the same value, not updating");
        }
    };

    phone.setPreferredAudioInputId = function (id) {
        console.debug("setPreferredAudioInputId() to: [" + id + "]");

        if (currentCalls.length !== 0) {
            todoWhenNoActiveCalls.push(function () {
                phone.setPreferredAudioInputId(id);
            });
            changeDevice(id, null);
            return;
        }

        if (phone.getPreferredAudioInputId() !== id) {
            localMediaGenerator.setPreferredAudioInputId(id);
            var logWarning = function (error) {
                console.warn("Could not start local capture: [" + error + "]");
            };
            if (typeof (localMediaStream) !== "undefined" && localMediaStream != null) {
                localMediaGenerator.getVideoOnlyStream(attachLocalMedia, logWarning, true);
            }
        } else {
            console.log("Capture audio input device id changed to the same value, not updating");
        }
    };

    phone.getPreferredCaptureResolution = function () {
        return localMediaGenerator.getPreferredCaptureResolution();
    };

    phone.getPreferredCaptureFrameRate = function () {
        return localMediaGenerator.getPreferredCaptureFrameRate();
    };

    phone.getPreferredVideoInputId = function () {
        return localMediaGenerator.getPreferredVideoInputId();
    };

    phone.getPreferredAudioInputId = function () {
        return localMediaGenerator.getPreferredAudioInputId();
    };

    phone.getPreferredAudioOutputId = function () {
        return localMediaGenerator.getPreferredAudioOutputId();
    };

    phone.setOnGetMediaDevices = function (success, recalculate=false) {
        if(recalculate){
            localMediaGenerator.setDevicesNeedEnumerating(true);    
        }
        localMediaGenerator.setOnGetMediaDevices(success);
    };

    phone.setRetainMediaAfterCall = function (retain) {
        retainMediaAfterCall = retain;
    };

    phone.setFacingVideo = function (facing, call) {
      if (!/user/.test(facing) && !/environment/.test(facing)) {
          console.warn("setFacingVideo was called with invalid parameter " + facing);
          return;
      }
      var logWarning = function (error) {
          console.warn("Could not start local capture: [" + error + "]");
      };
      if (typeof call !== 'undefined') {

          if (typeof localMediaStream !== 'undefined') {

              localMediaGenerator.stopCapture();
              if (typeof canvasMediaStream !== 'undefined') {
                  call.updateTrackForSending(canvasMediaStream.getVideoTracks(), canvasMediaStream);
              }
              localMediaGenerator.setFacingVideo(facing);
              localMediaGenerator.switchAudioAndVideoStream(
                  function (newStream) {
                      attachLocalMedia(newStream);
                      if (typeof localMediaStream !== 'undefined') {
                          call.updateTrackForSending(localMediaStream.getVideoTracks(), localMediaStream);
                          call.updateTrackForSending(localMediaStream.getAudioTracks(), localMediaStream);
                      }
                      const audioEnabled = phone.getAudioEnabled();
                      console.log("setAudioEnabled(audioEnabled=" + audioEnabled + ")");
                      if (currentCalls.length !== 0) {
                          const call = currentCalls[0];
                          call.setLocalMediaEnabled(true, audioEnabled);
                      }
                  }, logWarning, true);
          }
      } else {
          localMediaGenerator.stopCapture();
          localMediaGenerator.setFacingVideo(facing);

          if (typeof localMediaStream !== 'undefined') {
              localMediaGenerator.switchVideoOnlyStream(attachLocalMedia, logWarning, true);
          }
      }

      facingVideo = facing;
      if (mirrorModeSetting == 'Auto') {
        currentCalls.forEach((call) => call.mirrorModeChanged());
      }
    };

    phone.setMirrorMode = function (mode) {
        if (!(mode in phone.MirrorMode)) {
            throw new Error("Unexpected value provided to setMirrorMode");
        }
        mirrorModeSetting = mode;
        currentCalls.forEach((call) => call.mirrorModeChanged());
    }

    phone.createCall = function (numberToDial) {
        console.debug("createCall(), building call");
        var call = new PCCall(CallDirection.OUTBOUND, numberToDial, undefined, retainMediaAfterCall);
        console.debug("[" + call + "] - Created, returning");
        if (previewElementMediaRequested) {
            todoWhenNoActiveCalls.push(function () {
                requestMediaForPreviewElement(false);
            });
        }
        return call;
    };

    phone.getCalls = function () {
        return currentCalls;
    };

    phone.getCall = function (callID) {
        for (var i = 0; i < currentCalls.length; i++) {
            var call = currentCalls[i];
            if (call.getCallId() === callID) {
                return call;
            }
        }
        return null;
    };

    var incomingCall = function (jsonResult) {
        var newCall = new PCCall(CallDirection.INBOUND, jsonResult.remoteAddress, jsonResult.callId, retainMediaAfterCall);
        if (previewElementMediaRequested) {
            todoWhenNoActiveCalls.push(function () {
                requestMediaForPreviewElement(false);
            });
        }
        emitPhoneEvent("IncomingCall", newCall);
    };

    var triggerListener = function (jsonResult) {
        var callID = jsonResult.callId;
        if (typeof (callListeners[callID]) == "undefined") {
            console.warn("ERROR: Message received for invalid call - ID: [" + callID + "]");
        } else {
            callListeners[callID](jsonResult);
        }
    };

    var pingListener = function () {
        // On Ping, Pong, the other end will handle timeouts
        var swiftPong = {
            "type": "PONG"
        };

        messageHandler.sendSwiftMessage(swiftPong);

        pingTimer.startPingTimeout();
    };

    // Only the connect message can trigger the call to be created, this will always be called first
    // If it has not been, we are missing information and should reject the call
    messageHandler.addHandler("CONNECT", incomingCall);
    messageHandler.addHandler("PING", pingListener);

    // All other messages need to be added so they can trigger the call specific listener.
    messageHandler.addHandler("CALL_CONFIG", triggerListener);
    messageHandler.addHandler("OK", triggerListener);
    messageHandler.addHandler("ANSWER", triggerListener);
    messageHandler.addHandler("OFFER", triggerListener);
    messageHandler.addHandler("OFFER_REQUEST", triggerListener);
    messageHandler.addHandler("DISPLAYNAME", triggerListener);
    messageHandler.addHandler("END", triggerListener);
    messageHandler.addHandler("ERROR", triggerListener);
    messageHandler.addHandler("RINGING", triggerListener);
    messageHandler.addHandler("ESTABLISHED", triggerListener);
    messageHandler.addHandler("ICE_CANDIDATE", triggerListener);
    messageHandler.addHandler("FLOOR_CONTROL", triggerListener);
    messageHandler.addHandler("REMOTE_HELD", triggerListener);
    messageHandler.addHandler("REMOTE_UNHELD", triggerListener);


    // Let the gateway know what endpoint this is.
    console.debug("User-Agent: [" + navigator.userAgent + "]");
    var swiftCapabilities = {
        "type": "CAPABILITIES",
        "user-agent": navigator.userAgent
    };

    phone.initialisationSucessful = function (/*initialisationSuccessJson*/) {
        if (adapter.browserDetails.browser === "firefox") {
            swiftCapabilities.capabilities = {
                "requires-trickle-ice": true,
                "supports-bundle": true,
                "supports-renegotiation": false,
                "available-media-transports": ["WEBRTC_UDP"],
                "client-name": "Firefox",
                "client-version": adapter.browserDetails.version,
                "user-agent": navigator.userAgent
            };
        } else if (adapter.browserDetails.browser === "chrome") {
            swiftCapabilities.capabilities = {
                "requires-trickle-ice": false,
                "supports-bundle": true,
                "supports-renegotiation": false,
                "available-media-transports": ["WEBRTC_UDP"],
                "client-name": "Chrome",
                "client-version": adapter.browserDetails.version,
                "user-agent": navigator.userAgent
            };
        } else if (adapter.browserDetails.browser === "safari") {
            swiftCapabilities.capabilities = {
                "requires-trickle-ice": true,
                "supports-bundle": true,
                "supports-renegotiation": false,
                "available-media-transports": ["WEBRTC_UDP"],
                "client-name": "Safari",
                "client-version": adapter.browserDetails.version,
                "user-agent": navigator.userAgent
            };
            console.log(swiftCapabilities);
        }
        messageHandler.sendSwiftMessage(swiftCapabilities);
    };
    // This will cause local media to be requested for a preview element when there is no local media in place
    // This should be done in the initialisation successful callback to ensure that phone is triggered after
    // the constructor has finished, any dynamic codecs choices are complete,
    // and the dev has had the chance to allocate the Phone.onLocalMediaStream callback.
    var requestMediaForPreviewElement = function (settingElement) {

        console.log("settingElement", settingElement);
        console.log("retainMediaAfterCall", retainMediaAfterCall);

        if (settingElement || retainMediaAfterCall) {
            previewElementMediaRequested = true;

            var doNothing = function (/*error*/) {
                // They will be informed of failures when they try to make a call, we dont need to tell them at phone
                // point to simplify the API
            };

            localMediaGenerator.getVideoOnlyStream(attachLocalMedia, doNothing, true);
        }
    };

  /**
   * Call Media Status Codes
   *
   * @class
   * @private
   */
  var CallMediaStatusCode = {
      /**
       * No media has been negotiated on this connection yet
       */
      MEDIA_INITIAL: 0,
      /**
       * Indicates we have responded to a remote offer
       */
      REMOTE_MEDIA_ANSWERED: 1,
      /**
       * Indicates we have been offered remote media and need to answer
       */
      REMOTE_MEDIA_OFFERED: 2,
      /**
       * Indicates we have responded to a remote offer
       */
      REMOTE_REQUESTED_OFFER: 3,
      /**
       * Indicates we have offered local media and are waiting for an answer
       */
      LOCAL_MEDIA_OFFERED: 4,
      /**
       * The offer answer transaction is complete
       */
      MEDIA_ACTIVE: 5,
      /**
       * New media is requested on this connection
       */
      MEDIA_RENEGOTIATE: 6
  };

  /**
   * Call directions
   *
   * @class
   * @private
   */
  var CallDirection = {
      /** Call inbound from gateway */
      INBOUND: 0,
      /** Call outbound from client */
      OUTBOUND: 1
  };

  /**
   * Call Status Codes
   *
   * @class
   * @private
   */
  var CallStatusCode = {
      /**
       * The call has been created, but nothing has happened with it yet.
       */
      STATUS_INITIAL: 0,
      /**
       * Indicates user has accepted the call but we are not yet established
       */
      STATUS_ACCEPTED: 1,
      /**
       * Indicates we are established
       */
      STATUS_ESTABLISHED: 2,
      /**
       * Indicates the call has ended.
       */
      STATUS_ENDED: 5,
      /**
       * Indicates the user has put the call on hold
       */
      STATUS_HOLD: 6,
      /**
       * Indicates there was an error with the call and it should be discarded at the earliest opportunity.
       * Details will have been made available via any of the error related callbacks.
       */
      STATUS_ERROR: 7,
      /**
       * Indicates that the call could not be setup with signaling
       */
      STATUS_CALL_SETUP_FAILED: 8,
      /**
       * Indicates that an unknown error was received, the call will most likely end.
       */
      STATUS_UNKNOWN_ERROR: 9
  };

  /**
   * Call Error Codes
   *
   * @class
   */
  var ErrorCode = {
      /**
       * Attempted to dial an invalid number.
       */
      INVALID_NUMBER_DIALLED: 1000,
      /**
       * Call dialled with no audio or video.
       */
      CALL_DIALLED_NO_MEDIA: 1001,
      /**
       * Call failed in the initial (dial) phase.
       */
      CALL_FAIL_INITIAL: 1002,
      /**
       * Failure detected during an active call.
       */
      CALL_FAIL_AFTER_INITIAL: 1003,
      /**
       * Failed to create peer connection.
       */
      PEER_CONNECTION_FAILED: 1004,
      /**
       * Failed to create an SDP offer.
       */
      SDP_OFFER_CREATION_FAILED: 1005,
      /**
       * Failed to set an SDP offer.
       */
      SDP_OFFER_SET_FAILED: 1006,
      /**
       * Failed to create an SDP answer.
       */
      SDP_ANSWER_CREATION_FAILED: 1007,
      /**
       * Failed to set the an SDP answer.
       */
      SDP_ANSWER_SET_FAILED: 1008,
      /**
       * This action is not supported on the current browser.
       */
      NOT_SUPPORTED_ON_THIS_BROWSER: 1009,
      /**
       * Failed to due to a lack of media broker capacity
       */
      NO_MB_CAPACITY: 1010,
      /**
       * The request was terminated by the network
       */
      REQUEST_TERMINATED: 1011,
      /**
       * An entity on the network was unavailable
       */
      TEMPORARILY_UNAVAILABLE: 1012
  };

  /**
   * Contains various functions and parameters used for playing tones to the local user.
   * Also contains a deque of callbacks which will be run (in LIFO form) when tones have
   * finished playing. This enables sendDtmf to mute audio tracks while tones are playing,
   * and to restore the state after all tones have finished playing; and setLocalMediaEnabled
   * to change the eventual state of the audio to the desired state after the tones have
   * finished.
   *
   *
   * @class
   * @private
   */
  var DtmfPlayer = new function () {
      // Use the chrome version if the spec version isn't there
      window.AudioContext = window.AudioContext || window.webkitAudioContext;

      if (typeof (window.AudioContext) == "undefined") {
          this.playToneLocally = function (frequency1, frequency2) {
              console.log("Attempted to play tones: [" + frequency1 + ", " + frequency2 +
                  "] - but the AudioContext API was not available in this browser");
          };
          return;
      }

      // Volume the tones are played at, 0-1
      var toneVolume = 0.5;

      // For holding an audio context currently only used to play tones to the user.
      var audioContext = new AudioContext();
      // The volume control for the audio context.
      var audioContextGain = audioContext.createGain();
      audioContextGain.connect(audioContext.destination);
      audioContextGain.gain.value = toneVolume;

      // duration of a tone in seconds
      var toneLength = 0.2;
      // gap between tones in seconds
      var toneGap = 0.05;
      // the last time in seconds a tone finished playing
      var toneLastFinishTime = 0;

      var oscillators = [];
      var disconnectionTimer = null;

      // stack of functions to call after tone playback completed
      var callbacks = [];

      /*
          * Plays a tone to the local user for the time specified in toneLength ensuring it does not overlap with any
          * other tone(s) being played.
          */
      this.playToneLocally = function (frequency1, frequency2) {
          if (toneLastFinishTime < audioContext.currentTime) {
              toneLastFinishTime = audioContext.currentTime;
          }

          var startTime = toneLastFinishTime;
          var finishTime = toneLastFinishTime + toneLength;

          var osc1 = audioContext.createOscillator();
          osc1.type = 'sine';
          osc1.frequency.value = frequency1;
          osc1.connect(audioContextGain);
          var osc2 = audioContext.createOscillator();
          osc2.type = 'sine';
          osc2.frequency.value = frequency2;
          osc2.connect(audioContextGain);

          osc1.start(startTime);
          osc2.start(startTime);
          osc1.stop(finishTime);
          osc2.stop(finishTime);

          oscillators.push(osc1);
          oscillators.push(osc2);

          toneLastFinishTime = finishTime + toneGap;

          //disconnect the oscillators 1 second after any tones are complete
          if (disconnectionTimer != null) {
              clearTimeout(disconnectionTimer);
          }
          var oscs = oscillators;

          var cb = callbacks;

          var terminatePlayback = function () {
              // Disconnect all oscillators
              while (oscs.length > 0) {
                  var o = oscs.shift();
                  o.disconnect();
                  o = null;
              }
              // Execute all callbacks in reverse order
              while (cb.length > 0) {
                  var callback = cb.pop();
                  callback();
              }
          };
          var disconnectTime = (finishTime - audioContext.currentTime + 1) * 1000;
          disconnectionTimer = setTimeout(terminatePlayback, disconnectTime);
      };

      /**
       * Pushes a callback function onto the stack which will be executed when
       * tone playback is finished.
       */
      this.appendCallback = function (fn) {
          callbacks.push(fn);
      };

      /**
       * Returns true if the DtmfPlayer is currently playing a series of DTMF tones
       */
      this.isPlaying = function () {
          return (callbacks.length > 0);
      };

      /**
       * Inserts a callback function at the bottom of the stack, which will be executed
       * last when tone playback is finished. This enables actions which change the
       * state of the audio to push that new state to the bottom of the stack, ensuring
       * that the new state, not the original state, is restored when tone playback is
       * finished.
       */
      this.prependCallback = function (fn) {
          callbacks.unshift(fn);
      };

      /**
       * Terminates the current tone playback by preventing any existing terminatePlayback
       * timer function from running, disconnecting any oscillators, and running any
       * callbacks to restore state. This can be used when a call is terminated while
       * DTMF playback is in progress to prevent the unexpected playing of tones after
       * the call has ended.
       */
      this.terminateCurrentPlayback = function () {
          // Stop disconnection timer
          if (disconnectionTimer != null) {
              clearTimeout(disconnectionTimer);
              disconnectionTimer = null;
          }
          // Terminate oscillators
          while (oscillators.length > 0) {
              var o = oscillators.shift();
              o.disconnect();
              o = null;
          }
          // Execute all callbacks in reverse order
          while (callbacks.length > 0) {
              var callback = callbacks.pop();
              callback();
          }
      };

  };

  /**
   * The webrtc version of a call
   *
   * @constructor
   * @private
   * @param direction Call direction inbound or outbound - see CallDirection
   * @param remoteAddress the number to dial
   * @param callId the call id if created by the gateway
   * @param retain - whether to retain local media or release after call
   */
  function PCCall(direction, remoteAddress, callId, retain) {
      var that = this;
      const emitCallEvent = utils.emitEvent.bind(null, this);
      /* The Call status */
      var status = CallStatusCode.STATUS_INITIAL;
      /* The status of media for this call */
      var mediaStatus = CallMediaStatusCode.MEDIA_INITIAL;
      /* The status of retaining media */
      var retainMediaAfterCall = retain;

      /* When the call is answered, this should be set to the current Date() */
      var callStartTime = 0;

      /* Should this call have audio */
      var audio = true;
      /* Should this call have video */
      var video = true;

      /* Does the stream associated with this call have audio */
      var audioAvailable = false;
      /* Does the stream associated with this call have video */
      var videoAvailable = false;

      /* Remote party's display name */
      var remoteDisplayName = "";

      /* The locally generated SDP */
      var localSDP = "";
      /* The remote SDP */
      var remoteSDP = "";

      var remoteStreamLabels = [];

      /**
       * The peerConnection object constructed on a call-by-call basis
       * @type RTCPeerConnection
       */
      var peerConnection;
      /** The media stream from the client's camera/mic
       * @type MediaStream
      */
      var localMediaStream = null;
      /* The media stream from the client's screenshare */
      var localScreenShareStream = null;

      /* The elements to set for media */
      const localMediaRenderer = MediaRenderer("local main", true);
      const localScreenShareRenderer = MediaRenderer("local screenshare", true);
      const remoteMediaRenderer = {};

      /* If we should try to send audio */
      var sendAudio = true;
      /* If we should try to send video */
      var sendVideo = true;
      /* If we should try to receive audio */
      var receiveAudio = true;
      /* If we should try to receive video */
      var receiveVideo = true;

      /** Array containing SSRCs of streams that should be received by the browser. */
      let shouldBeReceivingMedia = [];
      const stats = Statistics((quality) => emitCallEvent("ConnectionQualityChanged", quality));

      /* flag to restart ice when we next send an offer - used to recover from network issues */
      var restartIce = false;

      this.held = false;

      var mediaHandler;

      this.setLocalMediaStream = function(stream) {
          localMediaStream = stream;
      };

      this.setIsHeld = function (held) {
          that.held = held;
      };

      this.getIsHeld = function () {
          return that.held;
      };

      this.mirrorModeChanged = function() {
        if (localMediaStream) {
          localMediaRenderer.renderStream(localMediaStream, phone.shouldMirrorVideo());
        }
      }

      /*
          * Modifies sdp by adding a session attribute line of form a=attribute:value.
          */
      var addSdpSessionAttributeLine = function (attributeName, value, sdp) {
          var attributeLine = "a=" + attributeName + ":" + value;
          return sdp + attributeLine;
      };

      var createImageAttrValue = function () {
          return "* send " + videoResolutionSendPreferences + " recv " + videoResolutionRecvPreferences;
      };

      const getStreamLabelForTrack = function(streamId, track) {
        const streamTrackId = streamId + ' ' + track.id;
        let streamLabel = null;
        if (track.kind == "audio") {
          return "main";
        }
        if (remoteStreamLabels) {
          streamLabel = remoteStreamLabels[streamTrackId];
        }

        if (!streamLabel) {
            // If the media broker didn't provide streams, we should give them internal names
            return "main";
        }
        return streamLabel;
      };

      var hiddenRendererDiv;

      /**
       * get or create a MediaRenderer for remote streams
       * @param {string} streamLabel 
       * @returns {MediaRenderer}
       */
      const getRemoteRenderer = function(streamLabel) {
        const resolutionListener = function(width, height) {
          console.debug("[" + that + "] - Firing onRemoteVideoResized() with: ["
              + width + "], [" + width + "], ["
              + streamLabel + "]");
          emitCallEvent("RemoteVideoResized", width, height, streamLabel);
        }
        let renderer = remoteMediaRenderer[streamLabel];

        if (renderer) {
          return renderer;
        }

        renderer = MediaRenderer("remote " + streamLabel, false, resolutionListener);
        remoteMediaRenderer[streamLabel] = renderer;

        return renderer;
      } 

      var addStreamLabelsToSwiftMessage = function (swiftMessage) {
          var addStreamLabelForStream = function (localStream, label) {
              // Check stream exists and has a video track
              if (localStream != null
                  && localStream.getVideoTracks()[0] != undefined) {
                  var trackId = localStream.id + " " + localStream.getVideoTracks()[0].id;
                  swiftMessage.streamLabels[trackId] = label;
              }
          };
          swiftMessage.streamLabels = {};
          addStreamLabelForStream(localMediaStream, "main");
          addStreamLabelForStream(localScreenShareStream, "slides");
      };

      /*
          * Send swift messages to the server, these will get their data from the call object's scope
          */
      var swiftSender = {
          sendConnect: function () {
              var swiftConnect = {
                  "type": "CONNECT",
                  "callId": callId,
                  "remoteAddress": remoteAddress
              };
              messageHandler.sendSwiftMessage(swiftConnect);
          },

          sendRinging: function () {
              var swiftRinging = {
                  "type": "RINGING",
                  "callId": callId
              };

              messageHandler.sendSwiftMessage(swiftRinging);
          },

          sendOffer: function () {
              // This is done here instead of in the media generator so all the internal SDP is kept "clean" as
              // chrome will not like the SDP if it contains these values

              var sdpToSend;
              if (sendVideo) {
                  console.debug("Adding image attributes to the SDP we are sending out");
                  sdpToSend = addSdpSessionAttributeLine("imageattr", createImageAttrValue(), localSDP);
              } else {
                  sdpToSend = localSDP;
              }
              var swiftOffer = {
                  "type": "OFFER",
                  "callId": callId,
                  "sdp": sdpToSend
              };
              addStreamLabelsToSwiftMessage(swiftOffer);

              messageHandler.sendSwiftMessage(swiftOffer);
          },

          sendAnswer: function () {
              // This is done here instead of in the media generator so all the internal SDP is kept "clean" as
              // chrome will not like the SDP if it contains these values

              var sdpToSend;
              if (sendVideo) {
                  console.debug("Adding image attributes to the SDP we are sending out");
                  sdpToSend = addSdpSessionAttributeLine("imageattr", createImageAttrValue(), localSDP);
              } else {
                  sdpToSend = localSDP;
              }

              var swiftAnswer = {
                  "type": "ANSWER",
                  "callId": callId,
                  "sdp": sdpToSend
              };
              addStreamLabelsToSwiftMessage(swiftAnswer);

              messageHandler.sendSwiftMessage(swiftAnswer);
          },

          sendOK: function () {
              var swiftOK = {
                  "type": "OK",
                  "callId": callId
              };

              messageHandler.sendSwiftMessage(swiftOK);
          },

          sendEnd: function () {
              var swiftEnd = {
                  "type": "END",
                  "callId": callId
              };

              messageHandler.sendSwiftMessage(swiftEnd);
          },

          sendDTMF: function (dtmfCode) {
              var swiftDtmf = {
                  "type": "DTMF",
                  "code": dtmfCode,
                  "callId": callId
              };
              messageHandler.sendSwiftMessage(swiftDtmf);
          },

          sendIceCandidate: function (candidate, mLineIndex, mid) {
              var swiftCandidate = {
                  "type": "ICE_CANDIDATE",
                  "candidate": candidate,
                  "sdpMLineIndex": mLineIndex,
                  "sdpMid": mid,
                  "callId": callId
              };
              messageHandler.sendSwiftMessage(swiftCandidate);
          },

          //only for browsers which don't support renegotiations
          sendHold: function () {
              var swiftHold = {
                  "type": "HOLD",
                  "callId": callId
              };
              messageHandler.sendSwiftMessage(swiftHold);
          },

          //only for browsers which don't support renegotiations
          sendResume: function () {
              var swiftResume = {
                  "type": "RESUME",
                  "callId": callId
              };
              messageHandler.sendSwiftMessage(swiftResume);
          }
      };

      /*
          * Tears down the call
          * @param shouldSendEnd true if we should send a swift end
          */
      var endCall = function (shouldSendEnd) {
          if (DtmfPlayer.isPlaying()) {
              DtmfPlayer.terminateCurrentPlayback();
          }

          removeCall(that);

          try {
              localMediaGenerator.stopMediaStream(localScreenShareStream);
          } catch (error) {
              // This can happen if it was already stopped
          }

          that.ending = true;

          localMediaGenerator.stopMediaStream(localMediaStream);
          // only if setRetainMediaAfterCall
          if (!retainMediaAfterCall) {
              localMediaGenerator.stopCapture();
          }
          localMediaStream = null;
          localScreenShareStream = null;
          for (let stream in remoteMediaRenderer) {
            remoteMediaRenderer[stream].close();
          }

          // Remove any hidden renderer div
          if (hiddenRendererDiv) {
            document.body.removeChild(hiddenRendererDiv);
          }

          if (typeof (peerConnection) !== "undefined") {
              try {
                  peerConnection.close();
              } catch (error) {
                  // This can happen if it was already closed
              }
          }

          that.bfcp.close();

          if (shouldSendEnd) {
              swiftSender.sendEnd();
          }
      };

      /*
          * Handle messages which trigger error callbacks
          */
      var errorHandler = function (jsonResult) {
          switch (jsonResult.errorType) {
              case "BUSY":
                  status = CallStatusCode.STATUS_ERROR;
                  console.debug("[" + that + "] - Firing onBusy() callback");
                  emitCallEvent("Busy");
                  endCall(false);
                  break;
              case "TIMEOUT":
                  status = CallStatusCode.STATUS_ERROR;
                  console.debug("[" + that + "] - Firing onTimeout() callback");
                  emitCallEvent("Timeout");
                  endCall(false);
                  break;
              case "NOTFOUND":
                  status = CallStatusCode.STATUS_ERROR;
                  console.debug("[" + that + "] - Firing onNotFound() callback");
                  emitCallEvent("NotFound");
                  endCall(false);
                  break;
              case "FAILED":
                  if (status == CallStatusCode.STATUS_INITIAL) {
                      var dialFailure = "Call setup detected with error: [ " + jsonResult.reasonPhrase + "]";
                      console.debug("[" + that + "] - Firing onDialFailed() callback with " + "message: [" +
                          dialFailure + "], [" + ErrorCode.CALL_FAIL_INITIAL + "]");
                      emitCallEvent("DialFailed", dialFailure, ErrorCode.CALL_FAIL_INITIAL);
                      endCall(false);
                      status = CallStatusCode.STATUS_ERROR;
                  } else {
                      var callFailure = "Call setup detected with error: [ " + jsonResult.reasonPhrase + "]";
                      var errorCodeToReturn = ErrorCode.CALL_FAIL_AFTER_INITIAL;
                      if (jsonResult.reasonPhrase == "No Media Brokers available") {
                          errorCodeToReturn = ErrorCode.NO_MB_CAPACITY;
                      } else if (jsonResult.reasonPhrase == "Temporarily Unavailable") {
                          errorCodeToReturn = ErrorCode.TEMPORARILY_UNAVAILABLE;
                      } else if (jsonResult.reasonPhrase == "Request Terminated") {
                          errorCodeToReturn = ErrorCode.REQUEST_TERMINATED;
                      }
                      console.debug("[" + that + "] - Firing onCallFailed() callback with message: [" +
                          callFailure + "], [" + errorCodeToReturn + "]");
                      emitCallEvent("CallFailed", callFailure, errorCodeToReturn);
                      endCall(false);
                      status = errorCodeToReturn;
                  }
                  break;
              default:
                  if (status == CallStatusCode.STATUS_INITIAL) {
                      var dialError = "Call setup detected with error: [ " + jsonResult.reasonPhrase + "]";
                      console.debug("[" + that + "] - Firing onDialFailed() callback with message: ["
                          + dialError + "], [" + ErrorCode.CALL_FAIL_INITIAL + "]");
                      emitCallEvent("DialFailed", dialError, ErrorCode.CALL_FAIL_INITIAL);
                  } else {
                      var callError = "Call Setup detected with error: [ " + jsonResult.reasonPhrase + "]";
                      console.debug("[" + that + "] - Firing onCallFailed() callback with message: [" +
                          callError + "], [" + ErrorCode.CALL_FAIL_AFTER_INITIAL + "]");
                      emitCallEvent("CallFailed", callError, ErrorCode.CALL_FAIL_AFTER_INITIAL);
                  }
                  endCall(false);
                  status = CallStatusCode.STATUS_UNKNOWN_ERROR;
          }
      };
      /*
          * Handle messages which change the call media state
          */

      var updateTrackEnabled = function (tracks, enabled) {
          for (var i = 0; i < tracks.length; i++) {
              var track = tracks[i];
              track.enabled = enabled;
          }
      };

      mediaHandler = {
          /*
              * If we need to, advance the media processing, this will be called after
              * any message which changes the call state or media state and also when the peer connection libraries gain
              * access to media.
              */
          nullCandidateTimeoutID: 0,

          generateMedia: function () {
              console.debug("Generating media for call: [" + callId + "]");
              // We cant generate media if the peer connection libraries aren't set up.
              if (typeof (peerConnection) === "undefined") {
                  console.debug("peerConnection was undefined, waiting to generate SDP");
                  return;
              }
              // Or if we dont have local media
              if (typeof (localMediaStream) === "undefined" || localMediaStream == null) {
                  console.debug("Local Media Stream not available yet, waiting to generate SDP");
                  return;
              }

              var createHoldSdp = function (sdp) {
                  console.debug("Converting SDP to Hold SDP");
                  sdp = sdp.replace(/sendrecv/g, "inactive");
                  sdp = sdp.replace(/sendonly/g, "inactive");
                  sdp = sdp.replace(/recvonly/g, "inactive");
                  return sdp;
              };

              var sdpFailureMessage = "SDP Negotiation Failed";

              var removeCodecNumber = function (sdp, number) {
                  var codecLinePattern = new RegExp("\\nm=.*" + number + ".*");
                  var codecLineOld = sdp.match(codecLinePattern)[0];
                  if (typeof (codecLineOld) != "undefined") {
                      var codecNumberPattern = new RegExp(" " + number + "(\\s|$)");
                      var codecLineNew = codecLineOld.replace(codecNumberPattern, " ");
                      sdp = sdp.replace(codecLineOld, codecLineNew);
                  }
                  var imageAttrPattern = new RegExp("\\na=imageattr:" + number + ".*");
                  var rtcpFBPattern = new RegExp("\\na=rtcp-fb:" + number + ".*");
                  var fmtpPattern = new RegExp("\\na=fmtp:" + number + ".*");

                  sdp = sdp.replace(imageAttrPattern, "");
                  sdp = sdp.replace(rtcpFBPattern, "");
                  sdp = sdp.replace(fmtpPattern, "");

                  return sdp;
              };

              var removeCodecLine = function (sdp, codec) {
                  var codecPattern = new RegExp("a=rtpmap:.*" + codec + "/.*\r\n", "gi");
                  var codecLines = sdp.match(codecPattern);
                  if (typeof (codecLines) !== "undefined" && codecLines) {
                      for (var i = 0; i < codecLines.length; i++) {
                          var codecNumber = codecLines[i].match(/\d+/);
                          sdp = removeCodecNumber(sdp, codecNumber);
                      }
                      sdp = sdp.replace(codecPattern, "");
                  } else {
                      console.log("No codec: [" + codec + "] found in SDP");
                  }
                  return sdp;
              };

              var generateSdpConstraints = function () {
                return {
                  'offerToReceiveAudio': receiveAudio,
                  'offerToReceiveVideo': receiveVideo
                };
              }

              // Disables video in an SDP string by setting the video port to 0
              var disableVideoInSdp = function (SDPString) {
                  return SDPString.replace(/(m=video )[0-9]*(.*)/, "$10$2");
              };

              var trimSDPLines = function (untrimmedSDP) {
                  var lines = untrimmedSDP.split("\r\n");
                  for (let i = 0; i < lines.length; i++) {
                      lines[i] = lines[i].trim();
                  }
                  var sdp = lines.join("\r\n");
                  return sdp;
              }

              var removeBannedCodecs = function (sdp) {
                  if (sdp.match(/a=rtpmap:[0-9]* CN/i) != null) {
                      console.debug("Removing CN");
                      sdp = removeCodecLine(sdp, "CN");
                  }
                  if (sdp.match(/a=rtpmap:[0-9]* ISAC/i) != null) {
                      console.debug("Removing ISAC");
                      sdp = removeCodecLine(sdp, "ISAC");
                  }
                  if (sdp.match(/a=rtpmap:[0-9]* telephone-event/i) != null) {
                      console.debug("Removing telephone-event");
                      sdp = removeCodecLine(sdp, "telephone-event");
                  }
                  var trimmedSDP = trimSDPLines(sdp);
                  return trimmedSDP;
              };

              var parseMediaDirectionsForMids = function (sdp) {
                  var sections = sdp.split(/m=[a-z]+ /);
                  var midDirections = new Map();
                  var directionRegex = /a=(sendrecv|sendonly|recvonly|inactive)/;
                  var midRegex = /a=mid:(.+)$/;
                  sections.forEach(function (line) {
                      if (line.search(/^\d+ /) === 0) {
                          let direction = "sendrecv";
                          let mid;
                          let attributes = line.split("\r\n");
                          for (let i = 0; i < attributes.length; i++) {
                              let regexResult;
                              if ((regexResult = midRegex.exec(attributes[i])) != null) {
                                  mid = regexResult[1];
                              } else if ((regexResult = directionRegex.exec(attributes[i])) != null) {
                                  direction = regexResult[1];
                              }
                          }
                          midDirections.set(mid, direction);
                      }
                  });
                  return midDirections;
              };

              var parseSsrcsForMids = function (sdp) {
                  var sections = sdp.split(/m=[a-z]+ /);
                  var midSsrcs = new Map();
                  var midRegex = /a=mid:(.+)$/;
                  var ssrcRegex = /a=ssrc:(\d+) /;
                  sections.forEach(function (line) {
                      if (line.search(/^\d+ /) === 0) {
                          let mid;
                          let ssrcs = [];
                          let attributes = line.split("\r\n");
                          for (let i = 0; i < attributes.length; i++) {
                              let regexResult;
                              if ((regexResult = midRegex.exec(attributes[i])) != null) {
                                  mid = regexResult[1];
                              } else if ((regexResult = ssrcRegex.exec(attributes[i])) !== null) {
                                  if (!ssrcs.includes(regexResult[1])) {
                                      ssrcs.push(regexResult[1]);
                                  }
                              }
                          }
                          midSsrcs.set(mid, ssrcs);
                      }
                  });
                  return midSsrcs;
              };

              var sendOffer = function () {
                  console.debug("Call with stream: " + localMediaStream.id);

                  var onOfferComplete = function (sessionDescription) {
                      if (status == CallStatusCode.STATUS_HOLD) {
                          sessionDescription.sdp = createHoldSdp(sessionDescription.sdp);
                      }

                      if (!receiveVideo) {
                          sessionDescription.sdp = disableVideoInSdp(sessionDescription.sdp);
                      }

                      localSDP = sessionDescription.sdp;
                      swiftSender.sendOffer();
                      mediaStatus = CallMediaStatusCode.LOCAL_MEDIA_OFFERED;
                  };

                  var onOfferFailed = function (error) {
                      console.warn("WARNING: The creation of the offer failed", error);
                      console.debug("[" + that + "] - Firing onCallFailed() callback with message: " +
                          sdpFailureMessage + "], [" + ErrorCode.SDP_OFFER_CREATION_FAILED + "]");
                      emitCallEvent("CallFailed", sdpFailureMessage, ErrorCode.SDP_OFFER_CREATION_FAILED);
                      endCall(true);
                  };

                  /** @type RTCOfferOptions */
                  const sdpContraints = generateSdpConstraints();
                  if (restartIce) {
                    // to recover from ice connection failure we request new ice ufrag/password
                    sdpContraints.iceRestart = true;
                    restartIce = false; // clear flag since it is actioned
                  } else {
                    addMediaStreams();
                  }
                  peerConnection.createOffer(sdpContraints).then(onOfferComplete).catch(onOfferFailed);
              };

              var sendAnswer = function () {
                  console.debug("Call with stream: " + localMediaStream.id);

                  var onAnswerComplete = function (sessionDescription) {
                      if (status == CallStatusCode.STATUS_HOLD) {
                          sessionDescription.sdp = createHoldSdp(sessionDescription.sdp);
                      }

                      var onSetLocalDescSuccess = function () {
                          localSDP = sessionDescription.sdp;
                          let midDirections = parseMediaDirectionsForMids(localSDP);
                          let midSsrcs = parseSsrcsForMids(remoteSDP);
                          let receivingSsrcs = [];
                          midDirections.forEach(function (direction, mid) {
                              if (direction === "sendrecv" || direction === "recvonly") {
                                  midSsrcs.get(mid).forEach(function (ssrc) {
                                      receivingSsrcs.push(ssrc);
                                  });
                              }
                          });
                          shouldBeReceivingMedia = receivingSsrcs;
                          swiftSender.sendAnswer();
                          mediaStatus = CallMediaStatusCode.MEDIA_ACTIVE;
                      };

                      var onSetLocalDescFailure = function () {
                          console.warn("WARNING: Setting the local answer failed");
                          console.debug("[" + that + "] - Firing onCallFailed() callback with message: [" +
                              sdpFailureMessage + "], [" + ErrorCode.SDP_ANSWER_SET_FAILED + "]");
                          emitCallEvent("CallFailed", sdpFailureMessage, ErrorCode.SDP_ANSWER_SET_FAILED);
                          endCall(true);
                      };

                      if (!receiveVideo) {
                          sessionDescription.sdp = disableVideoInSdp(sessionDescription.sdp);
                      }

                      peerConnection.setLocalDescription(sessionDescription)
                              .then(onSetLocalDescSuccess).catch(onSetLocalDescFailure);
                  };

                  var onAnswerFailed = function (error) {
                      console.warn("WARNING: The creation of the answer failed", error);
                      console.debug("[" + that + "] - Firing onCallFailed() callback with message: [" +
                          sdpFailureMessage + "], [" + ErrorCode.SDP_ANSWER_CREATION_FAILED + "]");
                      emitCallEvent("CallFailed", sdpFailureMessage, ErrorCode.SDP_ANSWER_CREATION_FAILED);
                      endCall(true);
                  };

                  var sessionDescription = { sdp: remoteSDP, type: "offer" };

                  var onSetRemoteDescSuccess = function () {
                    peerConnection.createAnswer().then(onAnswerComplete).catch(onAnswerFailed);
                  };

                  var onSetRemoteDescFailed = function () {
                      console.warn("WARNING: Setting remote description failed");
                      console.debug("[" + that + "] - Firing onCallFailed() callback with message: [" +
                          sdpFailureMessage + "], [" + ErrorCode.SDP_OFFER_SET_FAILED + "]");
                      emitCallEvent("CallFailed", sdpFailureMessage, ErrorCode.SDP_OFFER_SET_FAILED);
                      endCall(true);
                  };

                  addMediaStreams();

                  if (!receiveVideo) {
                      sessionDescription.sdp = disableVideoInSdp(sessionDescription.sdp);
                  }

                  peerConnection.setRemoteDescription(new RTCSessionDescription(sessionDescription))
                    .then(onSetRemoteDescSuccess).catch(onSetRemoteDescFailed);
              };

              var sendOK = function () {
                  console.debug("Call with stream: " + localMediaStream.id);

                  var onSetLocalDescSuccess = function () {
                      var onSetRemoteDescSuccess = function () {
                          let midDirections = parseMediaDirectionsForMids(remoteSDP);
                          let midSsrcs = parseSsrcsForMids(remoteSDP);
                          let receivingSsrcs = [];
                          midDirections.forEach(function (direction, mid) {
                              if (direction === "sendrecv" || direction === "sendonly") {
                                  midSsrcs.get(mid).forEach(function (ssrc) {
                                      receivingSsrcs.push(ssrc);
                                  });
                              }
                          });
                          shouldBeReceivingMedia = receivingSsrcs;
                          swiftSender.sendOK();
                      };

                      var onSetRemoteDescFailed = function () {
                          console.warn("WARNING: Setting remote description failed");
                          console.debug("[" + that + "] - Firing onCallFailed() callback with message: [" +
                              sdpFailureMessage + "], [" + ErrorCode.SDP_ANSWER_SET_FAILED + "]");
                          emitCallEvent("CallFailed", sdpFailureMessage, ErrorCode.SDP_ANSWER_SET_FAILED);
                          endCall(true);
                      };

                      mediaStatus = CallMediaStatusCode.MEDIA_ACTIVE;
                      var sessionDescription = { sdp: remoteSDP, type: "answer" };
                      peerConnection.setRemoteDescription(new RTCSessionDescription(sessionDescription))
                          .then(onSetRemoteDescSuccess).catch(onSetRemoteDescFailed);
                  };

                  var onSetLocalDescFailure = function (error) {
                      console.warn("WARNING: Setting the local description failed [" + error + "]");
                      console.debug("[" + that + "] - Firing onCallFailed() callback with message: [" +
                          sdpFailureMessage + "], [" + ErrorCode.SDP_OFFER_SET_FAILED + "]");
                      emitCallEvent("CallFailed", sdpFailureMessage, ErrorCode.SDP_OFFER_SET_FAILED);
                      endCall(true);
                  };

                  // Due to Chrome bug: 3481
                  if (remoteSDP.match(/a=rtpmap:[0-9]* rtx/i) == null) {
                      localSDP = removeCodecLine(localSDP, "rtx");
                  }

                  // Due to Chrome bug: 2350
                  var cleanLocalSDP = removeBannedCodecs(localSDP);

                  var sessionDescription = { sdp: cleanLocalSDP, type: "offer" };

                  peerConnection.setLocalDescription(new RTCSessionDescription(sessionDescription))
                    .then(onSetLocalDescSuccess).catch(onSetLocalDescFailure);
              };

              if (status == CallStatusCode.STATUS_ACCEPTED || status == CallStatusCode.STATUS_ESTABLISHED ||
                  status == CallStatusCode.STATUS_HOLD) {
                  console.debug("Call in state: [" + status + "], triggering SDP negotiation");

                  switch (mediaStatus) {
                      case CallMediaStatusCode.REMOTE_MEDIA_OFFERED:
                          console.debug("Remote party offered, generating Answer");
                          sendAnswer();
                          break;
                      case CallMediaStatusCode.REMOTE_REQUESTED_OFFER:
                          console.debug("Remote party requested offer, generating Offer");
                          sendOffer();
                          break;
                      case CallMediaStatusCode.REMOTE_MEDIA_ANSWERED:
                          console.debug("Remote party answered, generating OK");
                          sendOK();
                          break;
                      case CallMediaStatusCode.MEDIA_INITIAL:
                          if (direction == CallDirection.INBOUND) {
                              console.debug("Inbound call in initial state - No media to generate, continue waiting for OFFER or OFFER_REQUEST from server");
                          } else if (direction == CallDirection.OUTBOUND) {
                              console.debug("Local party initiating negotiation, generating initial Offer");
                              sendOffer();
                          } else {
                              console.error("ERROR - Media generation with unknown call direction");
                          }
                          break;
                      case CallMediaStatusCode.MEDIA_RENEGOTIATE:
                          console.debug("Local party initiating negotiation, generating subsequent Offer");
                          sendOffer();
                          break;
                      default:
                          console.debug("Media in state: [" + mediaStatus + "] - No further SDP negotiation needed");
                          break;
                  }
              } else {
                  console.debug("Call in state: [" + status +
                      "] - Call has not been accepted by the user, not generating SDP");
              }
          },

          handleRemoteOffer: function (jsonResult) {
              remoteSDP = jsonResult.sdp;

              remoteStreamLabels = jsonResult.streamLabels;
              mediaStatus = CallMediaStatusCode.REMOTE_MEDIA_OFFERED;
              this.generateMedia();
          },
          handleRemoteOfferRequest: function () {
              mediaStatus = CallMediaStatusCode.REMOTE_REQUESTED_OFFER;
              this.generateMedia();
          },
          handleRemoteAnswer: function (jsonResult) {
              remoteSDP = jsonResult.sdp;
              remoteStreamLabels = jsonResult.streamLabels;
              mediaStatus = CallMediaStatusCode.REMOTE_MEDIA_ANSWERED;
              this.generateMedia();
          },
          handleRemoteOK: function () {
              mediaStatus = CallMediaStatusCode.MEDIA_ACTIVE;
          },
          handleLocalHold: function () {
              mediaStatus = CallMediaStatusCode.MEDIA_RENEGOTIATE;
              status = CallStatusCode.STATUS_HOLD;
              this.generateMedia();
          },
          handleLocalResume: function () {
              mediaStatus = CallMediaStatusCode.MEDIA_RENEGOTIATE;
              status = CallStatusCode.STATUS_ACCEPTED;
              this.generateMedia();
          },
          handleLocalStreamUpdate: function () {
              mediaStatus = CallMediaStatusCode.MEDIA_RENEGOTIATE;
              addMediaStreams();
              this.generateMedia();
          },
          handleAcceptCall: function () {
              status = CallStatusCode.STATUS_ACCEPTED;
              this.generateMedia();
          },
          handleCandidate: function (jsonResult) {
              var onIceError = function (error) {
                  console.error("Failed to add Ice" + error);
              };
              var onIceSuccess = function () {
                  console.debug("Add ice candidate was successful");
              };

              var readyForIce = function (candidate) {
                  if (mediaStatus == CallMediaStatusCode.MEDIA_ACTIVE) {
                      peerConnection.addIceCandidate(candidate)
                          .then(onIceSuccess, onIceError);
                  } else {
                      window.setTimeout(function () {
                          readyForIce(candidate);
                      }, 100);
                  }
              };
              var iceCandidate = new RTCIceCandidate({
                  sdpMLineIndex: jsonResult.sdpMLineIndex,
                  sdpMid: jsonResult.sdpMid, candidate: jsonResult.candidate
              });
              readyForIce(iceCandidate);
          },
          handleCallConfig: function (jsonResult) {
              if (typeof (peerConnection) === "undefined") {
                  var allIceServers = [];
                  var i;
                  for (i = 0; i < jsonResult.iceServers.length; i++) {
                      var iceConfig = jsonResult.iceServers[i];
                      if (iceConfig.urls) {
                          let iceServer = createIceServer(iceConfig.urls, iceConfig.username, iceConfig.credential);
                          allIceServers = allIceServers.push(iceServer);
                      }
                  }
                  if (Array.isArray(phone.stunServers)) {
                      allIceServers = allIceServers.concat(phone.stunServers);
                  }

                  console.debug("Creating peer connection with iceServers [" + allIceServers + "]");
                  createPeerConnection(allIceServers);

                  // Process media changes required
                  this.generateMedia();
              } else {
                  console.error("Received call configuration when peerConnection is already created" +
                      "- cannot apply configuration");
              }
          },
          setAudioEnabled: function (enabled) {
              console.log("webrtc setAudioEnabled=" + enabled);
              var tracks = localMediaStream.getAudioTracks();
              updateTrackEnabled(tracks, enabled);
              phone.setAudioEnabled(enabled);
          },
          setVideoEnabled: function (enabled) {
              console.log("webrtc setVideoEnabled=" + enabled);
              var tracks = localMediaStream.getVideoTracks();
              updateTrackEnabled(tracks, enabled);
              phone.setVideoEnabled(enabled);
          },
          negotiateIceRestart: function() {
            restartIce = true;
            mediaStatus = CallMediaStatusCode.MEDIA_RENEGOTIATE;
            this.generateMedia();
          }
      };

      /*
          * Handle messages which change the call state
          */
      var callStateHandler = {
          handleEstablished: function () {
              status = CallStatusCode.STATUS_ESTABLISHED;
              console.debug("[" + that + "] - Firing onInCall() callback for :", that);
              if (UC?.background?.videoEffect) UC.background.videoEffect.trigger("InCall", that);
              emitCallEvent("InCall");
          },
          handleDisplayname: function (jsonResult) {
              remoteDisplayName = jsonResult.displayName;
              console.debug("[" + that + "] - Firing onRemoteDisplayNameChanged() callback");
              emitCallEvent("RemoteDisplayNameChanged");
          },
          handleRinging: function () {
              console.debug("[" + that + "] - Firing onRinging() callback");
              emitCallEvent("Ringing");
          },
          handleEnd: function () {
              status = CallStatusCode.STATUS_ENDED;
              console.debug("[" + that + "] - Firing onEnded() callback");
              emitCallEvent("Ended");
              endCall(false);
          },
          handleHeld: function () {
              console.debug("[" + that + "] - Firing onRemotePartyHeld() callback");
              that.setIsHeld(true);
              emitCallEvent("RemoteHeld");
          },
          handleUnheld: function () {
              console.debug("[" + that + "] - Firing onRemotePartyUnheld() callback");
              that.setIsHeld(false);
              emitCallEvent("RemoteUnheld");
          }
      };

      /*
          * The handler attached to this call, this will pass off to either errorHandler, callStateHandler
          * or MediaHandler depending on the type of message
          *
          * @param jsonResult
          */
      var callHandler = function (jsonResult) {
          switch (jsonResult.type) {
              // Call Setup Callbacks
              case "CALL_CONFIG":
                  mediaHandler.handleCallConfig(jsonResult);
                  break;
              //Media State Callbacks
              case "OFFER_REQUEST":
                  mediaHandler.handleRemoteOfferRequest();
                  break;
              case "ANSWER":
                  mediaHandler.handleRemoteAnswer(jsonResult);
                  break;
              case "OFFER":
                  mediaHandler.handleRemoteOffer(jsonResult);
                  break;
              case "OK":
                  mediaHandler.handleRemoteOK();
                  break;
              case "ICE_CANDIDATE":
                  mediaHandler.handleCandidate(jsonResult);
                  break;
              //Call State Callbacks
              //Connect Message will be dealt with by the phone object as this creates a call.
              case "ESTABLISHED":
                  callStateHandler.handleEstablished();
                  break;
              case "DISPLAYNAME":
                  callStateHandler.handleDisplayname(jsonResult);
                  break;
              case "RINGING":
                  callStateHandler.handleRinging();
                  break;
              case "END":
                  callStateHandler.handleEnd();
                  break;
              case "REMOTE_HELD":
                  callStateHandler.handleHeld();
                  break;
              case "REMOTE_UNHELD":
                  callStateHandler.handleUnheld();
                  break;
              // Errors
              case "ERROR":
              case "GENERIC_ERROR":
                  errorHandler(jsonResult);
                  break;
              // Floor Control messages
              case "FLOOR_CONTROL":
                  that.bfcp.handleFloorControl(jsonResult);
                  break;
          }
      };

      var storeStreamInfo = function (stream) {
          audioAvailable = stream.getAudioTracks().length > 0;
          videoAvailable = stream.getVideoTracks().length > 0;
      };

      /*
          * Creates the necessary streams
          * @param mediaConstraints
          */
      var attachLocalMedia = function () {
          var onUserMediaSuccess = function (stream) {
              localMediaStream = stream;
              localMediaRenderer.renderStream(stream, phone.shouldMirrorVideo());
              storeStreamInfo(stream);

              // Check if we have access to all the requested media
              if (sendAudio && !audioAvailable) {
                  emitCallEvent("OutboundAudioFailure");
              }
              if (sendVideo && !videoAvailable) {
                  emitCallEvent("OutboundVideoFailure");
              }

              console.debug("[" + that + "] - Firing onLocalMediaStream() callback");
              emitCallEvent("LocalMediaStream", localMediaStream);
              mediaHandler.generateMedia();
          };

          var onUserMediaError = function (error) {
              var userMediaMessage = "Could not get user media, error was: [" + error + "]";
              console.debug("[" + that + "] - Firing onGetUserMediaError() callback with message: [" +
                  userMediaMessage + "]");
              emitCallEvent("GetUserMediaError", userMediaMessage);
              endCall(true);
          };

          getStreamForCall(onUserMediaSuccess, onUserMediaError);
      };

      var getStreamForCall = function (success, failure) {
          if (sendAudio && sendVideo) {
              localMediaGenerator.getAudioVideoStream(success, failure);
          } else if (sendAudio && !sendVideo) {
              localMediaGenerator.getAudioOnlyStream(success, failure);
          } else if (!sendAudio && sendVideo) {
              localMediaGenerator.getVideoOnlyStream(success, failure);
          } else {
              localMediaGenerator.getNoMediaStream(success, failure);
              /*
              var noMediaError = "Call dialled with no audio or video";
              console.debug("[" + that + "] - Firing onDialFailed() callback with message: [" + noMediaError +
                  "], [" + ErrorCode.CALL_DIALLED_NO_MEDIA + "]");
              that.onDialFailed(noMediaError, ErrorCode.CALL_DIALLED_NO_MEDIA);
              */
          }
      };

      /*
      * Adds the localMediaStream object to the peerConnection object via the addStream() method,
      * <b>provided that:</b>
      *
      * (a) The existing MediaStream object on the peerConnection is not the same as the localMediaSteam object and
      * (b) All existing MediaStream objects on the peerConnection are removed first.
      */
      var addMediaStreams = function () {
          if (localMediaStream == null) {
              return;
          }

          if (peerConnection) {
              var existingMedia = peerConnection.getLocalStreams();
              var streamsToRemove = [];
              var i;

              for (i = 0; i < existingMedia.length; i++) {
                  streamsToRemove.push(existingMedia[i]);
              }

              for (i = 0; i < streamsToRemove.length; i++) {
                  peerConnection.removeStream(streamsToRemove[i]);
                  console.debug("Removed stream with id " + streamsToRemove[i].id + " from peerConnection");
              }

              if (peerConnection.getLocalStreams().length == 0) {
                  peerConnection.addStream(localMediaStream);
                  console.debug("Added stream with id " + localMediaStream.id +
                      " (local media stream.) to peerConnection");
                  if (localScreenShareStream) {
                      peerConnection.addStream(localScreenShareStream);
                      console.debug("Added stream with id " + localScreenShareStream.id +
                          " (local media stream.) to peerConnection");
                  }
              }
          }
      };

      /**
      * Create peerConnection
      * @param {RTCIceServer[]} iceServers
      */
      var createPeerConnection = function (iceServers) {
          // Add Stun Servers
          var pcConfig = { "iceServers": iceServers, "sdpSemantics": "unified-plan" };
          var pcConstraints = {};

          try {
              peerConnection = new RTCPeerConnection(pcConfig, pcConstraints);

              peerConnection.ontrack = function (event) {
                const stream = event.streams[0];
                if (!stream) {
                  return;
                }
                console.debug("track event: %s trid=%s msid=%s", event.track.kind,  event.track.id, stream.id);

                const streamLabel = getStreamLabelForTrack(stream.id, event.track);
                const renderer = getRemoteRenderer(streamLabel);
                if (streamLabel == "main" && !renderer.hasContainer()) {
                  // add a hidden div to render audio
                  hiddenRendererDiv = document.createElement("DIV");
                  hiddenRendererDiv.id = "hidden-" + callId;
                  hiddenRendererDiv.style.display = "none";
                  document.body.appendChild(hiddenRendererDiv);
                  renderer.setContainer(hiddenRendererDiv);
                }
    
                renderer.setStream(stream);
                renderer.render();
  
  
                stream.oninactive = function () {
                    console.debug("[" + that + "] - Firing onVideoStreamEnded() with: ["
                        + streamLabel + "]");
                    emitCallEvent("RemoteVideoRemoved", streamLabel);
                };

                // We only want to do this once per stream
                if ((event.track.kind === "audio" && !receiveVideo) ||
                    (event.track.kind === "video" && receiveVideo)) {
                    console.debug("[" + that + "] - Firing onRemoteMediaStream() with: [" + stream + "]");
                    emitCallEvent("RemoteMediaStream", stream);
                }
              };
              
              let disconnectTimer;

              var handleContinuingDisconnect = function() {
                if (mediaStatus == CallMediaStatusCode.MEDIA_ACTIVE) {
                  mediaHandler.negotiateIceRestart();
                }
              }

              peerConnection.oniceconnectionstatechange = function() {
                console.debug(new Date() + " iceConnectionState: " + peerConnection.iceConnectionState);
                if (adapter.browserDetails.browser == "safari" &&
                    peerConnection.iceConnectionState == "disconnected") {
                      disconnectTimer = setTimeout(handleContinuingDisconnect, 3000);
                }
                
                if (peerConnection.iceConnectionState != "disconnected" && 
                    peerConnection.iceConnectionState != "failed") {
                  // recovered from disconnect - cancel timeout
                  clearTimeout(disconnectTimer);
                }
              }

              peerConnection.onicecandidate = function (iceCandidate) {
                  if (iceCandidate.candidate) {
                      var candidate = iceCandidate.candidate;
                      if (candidate !== null) {
                          swiftSender.sendIceCandidate(candidate.candidate, candidate.sdpMLineIndex, candidate.sdpMid);
                      }
                  }
              };

              /**
               * get a Receiver of the specified type
               * 
               * @param {String} type 
               * @returns {RTCRtpReceiver}
               */
              let getReceiver = function(type) {
                let receivers = peerConnection.getReceivers();
                for (let receiver of receivers) {
                  if (receiver.track.kind == type) {
                    // use first one for now
                    return receiver;
                  }
                }
                return null;
              }

              let collectStats = function () {
                  // Don't bother with stats if we're not in a good state for it
                  if (status == CallStatusCode.STATUS_ENDED
                      || status == CallStatusCode.STATUS_ERROR
                      || status == CallStatusCode.STATUS_CALL_SETUP_FAILED
                      || status == CallStatusCode.STATUS_UNKNOWN_ERROR) {
                      return;
                  }

                  if (receiveVideo) {
                    let receiver = getReceiver("video");
                    if (receiver) {
                      receiver.getStats().then(handleStatsReport, statsError);
                    } else {
                      setTimeout(() => collectStats(), 1000);
                    }
                  } else if (receiveAudio) {
                    let receiver = getReceiver("audio");
                    if (receiver) {
                      receiver.getStats().then(handleStatsReport, statsError);
                    } else {
                      setTimeout(() => collectStats(), 1000);
                    }
                  }
              };

              // Handler for standard format stats
              let handleStatsReport = function (report) {
                  stats.processStats(report, shouldBeReceivingMedia);
                  setTimeout(() => collectStats(), 5000);
              };

              let statsError = function (error) {
                  console.log("statsError: " + error);
              };

              // Safari does not support stats before version 12.1
              if ('getStats' in window.RTCRtpReceiver.prototype) {
                setTimeout(() => collectStats(), 5000);
              } else {
                console.warn("getStat() not supported");
              }

              console.debug('Created RTCPeerConnnection with:\n' + '  config: \'' + JSON.stringify(pcConfig) +
                  '\';\n' + '  constraints: \'' + JSON.stringify(pcConstraints) + '\'.');
            } catch (error) {
              console.debug("Failed to create PeerConnection, exception:", error);
              var warning = "Could not create WebRTC - Was the media authorized?";
              console.debug("[" + that + "] - Firing onCallFailed() callback with message: [" + warning
              + "], [" + ErrorCode.PEER_CONNECTION_FAILED + "]");
              emitCallEvent("CallFailed", warning, ErrorCode.PEER_CONNECTION_FAILED);
              endCall(true);
          }

          // We should be able to do updateIce here but this is unimplemented in chrome and just throws
          // an exception. Once we can use this, it should simplify the code in the mediaHandler and should be done
          // peerConnection.updateIce();
      };

      /*
          * Construct a call, creating a peerConnection object, adding listeners to the global listener handler,
          * getting a local stream from the client and adding this call object to the global list.
          */
      var setupCall = function () {
          currentCalls.push(that);

          // if the call id was not defined, we are creating the call and should generate one
          if (typeof (callId) == "undefined") {
              callId = function () {
                  // http://www.ietf.org/rfc/rfc4122.txt
                  var s = [];
                  var hexDigits = "0123456789abcdef";
                  for (var i = 0; i < 36; i++) {
                      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                  }
                  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
                  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the
                  // clock_seq_hi_and_reserved
                  // to 01
                  s[8] = s[13] = s[18] = s[23] = "-";

                  return "BG-" + s.join("");
              }();
          } else {
              // if we had a call id, it was an incoming call and we should send a ringing.
              swiftSender.sendRinging();
          }
          callListeners[callId] = callHandler;
          console.debug('call ' + callId + ' setup');
      };
      setupCall();

      // now we have a callId can create the bfcp object
      this.bfcp = BFCP(messageHandler, callId);

      /**
       * Places an outbound call to the specified address offering audio & video.
       *
       * @param {string} withAudio - whether the call will have audio
       * @param {string} withVideo - whether the call will have video.
       *
       * Unless both parameters are defined, or if both parameters are set to false, the dialling will fail.
       */
      this.dial = function (withAudio, withVideo) {
          console.debug("[" + that + "] - dial() with audio: [" + withAudio + "], video: [" + withVideo + "]");
          var validNumber = false;

          if (typeof (remoteAddress) === "number" && remoteAddress > 0) {
              validNumber = true;
          } else if (typeof (remoteAddress) === "string" && remoteAddress.length > 0) {
              validNumber = true;
          }

          if (!validNumber) {
              var error = "The remote address is not valid";
              console.debug("[" + that + "] - Firing onDialFailed() with message: [" + error +
                  "], [" + ErrorCode.INVALID_NUMBER_DIALLED + "]");
              emitCallEvent("DialFailed", error, ErrorCode.INVALID_NUMBER_DIALLED);
              endCall(false);
              return;
          }

          // for backwards compatibility, no parameters passed must create an audio + video call
          if (arguments.length > 0) {
              const audioDirection = mediaDirection(withAudio);
              const videoDirection = mediaDirection(withVideo);
              sendAudio = audioDirection.send;
              sendVideo = videoDirection.send;
              receiveAudio = audioDirection.receive;
              receiveVideo = videoDirection.receive;
          }

          attachLocalMedia();

          // Don't continue setting up the call if a media issue has caused it to end
          if (!that.ending) {
              swiftSender.sendConnect();
              callStartTime = new Date();
              mediaHandler.handleAcceptCall();
          }
      };

      /**
       * Answers an inbound call to the user, offering audio & video.
       *
       * @param {string} withAudio - whether the call will have audio
       * @param {string} withVideo - whether the call will have video.
       */

      this.answer = function (withAudio, withVideo) {
          console.debug("[" + that + "] - answer() with audio: [" + withAudio + "], video: [" + withVideo + "]");
          //for backwards compatibility, no parameters passed must create an audio + video call
          if (arguments.length > 0) {
            const audioDirection = mediaDirection(withAudio);
            const videoDirection = mediaDirection(withVideo);
            sendAudio = audioDirection.send;
            sendVideo = videoDirection.send;
            receiveAudio = audioDirection.receive;
            receiveVideo = videoDirection.receive;
        }

          attachLocalMedia();
          callStartTime = new Date();
          mediaHandler.handleAcceptCall();
      };

      this.setPreviewElement = function (element) {
          console.debug("[" + that + "] - setPreviewElement() with: [" + element + "]");
          localMediaRenderer.setContainer(element);
          if (localMediaStream) {
            localMediaRenderer.renderStream(localMediaStream, phone.shouldMirrorVideo());
          }
      };

      this.setScreenshareElement = function (element) {
          console.debug("[" + that + "] - setScreenshareElement() with: [" + element + "]");
          localScreenShareRenderer.setContainer(element);
          if (localScreenShareStream) {
            localScreenShareRenderer.renderStream(localScreenShareStream, false);
          }
      };

      this.setVideoElement = function (element, streamLabel) {
          console.debug("[" + that + "] - setVideoElement() with: [" + element + "]," +
              "identifier: [" + streamLabel + "]");

          if (!streamLabel) {
              console.debug("No identifier set, using 'main'");
              streamLabel = "main";
          }

          const renderer = getRemoteRenderer(streamLabel);
          renderer.setContainer(element);
          if (streamLabel == "main" && hiddenRendererDiv) {
            // don't need the hidden div now that we have a real one
            document.body.removeChild(hiddenRendererDiv);
            hiddenRendererDiv = null;
          }
          renderer.render();
      };

      /**
       * Ends an existing call.
       */
      this.end = function () {
          console.debug("[" + that + "] - end()");
          status = CallStatusCode.STATUS_ENDED;
          endCall(true);
          if (UC?.background?.videoEffect) UC.background.videoEffect.trigger("callEnded", that);
      };

      this.getNewDevice = function(success, failure){
          getStreamForCall(success, failure);
      }

      // Switch Track for in-call Camera Switch
      this.updateTrackForSending = function (tracks, stream) {
          if (typeof tracks !== 'undefined' && tracks.length > 0) {
              let track = tracks[0];
              let sender = peerConnection.getSenders().find(function (s) {
                  return s.track.kind == track.kind
              });
              if (typeof sender !== 'undefined') {
                  sender.replaceTrack(track).then(function () {
                      console.log("Replaced track succeeded:" + track.label);
                  }).catch(function (err) {
                      console.warn("Replace track resulted in an error " + JSON.stringify(err));
                  })
              }
              that.setLocalMediaStream(stream);
          } else {
              console.warn("updateTrackForSending called with no current tracks");
          }
      };

      var isScreenshareEnabled = false;
      this.enableScreenshare = function (enabled) {
          console.debug("[" + that + "] - enableScreenshare with: [" + enabled + "]");
          var startScreenshare = function () {
              var onGetMediaStreamSuccess = function (stream) {
                  localScreenShareStream = stream;

                  var videoElement = document.createElement("video");
                  videoElement.srcObject = stream;

                  videoElement.onloadedmetadata = function () {
                      console.debug("[" + that + "] - Firing onScreensharePreviewAdded()");
                      emitCallEvent("ScreensharePreviewAdded");
                      console.debug("[" + that + "] - Firing onScreensharePreviewResized() with: ["
                          + videoElement.videoWidth + "], [" + videoElement.videoHeight + "]");
                      emitCallEvent("ScreensharePreviewResized", videoElement.videoWidth, videoElement.videoHeight);
                  };
                  stream.oninactive = function () {
                      isScreenshareEnabled = false;
                      console.debug("[" + that + "] - Firing onScreensharePreviewRemoved()");
                      emitCallEvent("ScreensharePreviewRemoved");
                  };

                  mediaHandler.handleLocalStreamUpdate();
                  localScreenShareRenderer.renderStream(stream, false);
                  console.debug("[" + that + "] - Firing onLocalMediaStream() with: [" + stream + "]");
                  emitCallEvent("LocalMediaStream", stream);
                  emitCallEvent("GetScreenshareSuccess");
              };

              var getLocalScreenshareError = function (error) {
                  isScreenshareEnabled = false;
                  var getScreenshareError = "Failed to get screenshare media. " + error;
                  console.debug("[" + that + "] - Firing onGetScreenshareError with: [" + getScreenshareError + "]");
                  emitCallEvent("GetScreenshareError", getScreenshareError);
              };

              var getLocalScreenshareCancelled = function () {
                  isScreenshareEnabled = false;
                  console.debug("[" + that + "] - Firing getScreenshareCancel");
                  emitCallEvent("GetScreenshareCancel");
              };

              localMediaGenerator.getScreenshare(onGetMediaStreamSuccess, getLocalScreenshareError, getLocalScreenshareCancelled);
              isScreenshareEnabled = true;
          };

          var endScreenshare = function () {
              localMediaGenerator.stopMediaStream(localScreenShareStream);
              localScreenShareStream = null;
              mediaHandler.handleLocalStreamUpdate();
              isScreenshareEnabled = false;
          };

          if (typeof (enabled) === "undefined") {
              if (isScreenshareEnabled) {
                  endScreenshare();
              } else {
                  startScreenshare();
              }
          } else if (enabled && isScreenshareEnabled) {
              console.warn("Screenshare was already enabled, ignoring");
          } else if (!enabled && !isScreenshareEnabled) {
              console.warn("Screenshare wasn't enabled, ignoring");
          } else if (enabled) {
              startScreenshare();
          } else {
              endScreenshare();
          }
      };

      /**
       * Enables or disables local audio or video on a call.
       * <p>
       * This effectively mutes or un-mutes local media, stopping it from being sent while still being able to
       * receive media from the remote end.
       * </p>
       *
       * @param {boolean|object} enableVideo Whether video should be enabled or an object describing the
       * desired state
       *
       * <p>
       * This function can either be passed the two boolean parameters to toggle audio/video (set to true or
       * false) or a single object which defines the enabled state.
       * </p>
       *
       * <p>
       * Use of a single object is required if using screenshare. If using the object method,
       * it should take the following form:
       * <ul>
       * <li> To configure screenshare on {"screenshare":true} </li>
       * <li> To configure screenshare off {"screenshare":false} </li>
       * <li> To configure audio and video on {"audio": true, "video": true} </li>
       * <li> To configure audio and video off {"audio": false, "video": false} </li>
       * </ul>
       * </p>
       *
       * @param {boolean} [enableAudio] Whether audio should be enabled, this should be provided unless using the
       * object method as previously described
       */
      this.setLocalMediaEnabled = function (enableVideo, enableAudio) {
          var onGetMediaStreamSuccess = function (stream) {
              localMediaStream = stream;
              mediaHandler.handleLocalStreamUpdate();
              localMediaRenderer.renderStream(localMediaStream, phone.shouldMirrorVideo());
              console.debug("[" + that + "] - Firing onLocalMediaStream() with: [" + stream + "]");
              emitCallEvent("LocalMediaStream", stream);
          };

          var swapToScreenshare = function () {
              var getLocalScreenshareError = function (error) {
                  var getScreenshareError = "Failed to get screenshare media. " + error;
                  console.debug("[" + that + "] - Firing onGetScreenshareError with: [" + getScreenshareError + "]");
                  emitCallEvent("GetScreenshareError", getScreenshareError);
              };

              localMediaGenerator.getScreenshare(onGetMediaStreamSuccess, getLocalScreenshareError);
          };

          var swapToAudioVideo = function () {
              var getAudioVideoError = function (error) {
                  var avError = "Unable to obtain AV stream on switching back from screenshare. " + error;
                  console.debug("[" + that + "] - Firing onGetUserMediaError() with: [" + avError + "]");
                  emitCallEvent("GetUserMediaError", avError);
                  endCall(true);
              };

              getStreamForCall(onGetMediaStreamSuccess, getAudioVideoError);
          };

          var handleAudioAndVideo = function (enableVideo, enableAudio) {
              if (!DtmfPlayer.isPlaying()) {
                  // Immediately enable/disable video and audio
                  if (enableAudio != undefined) {
                      mediaHandler.setAudioEnabled(enableAudio);
                  }
                  if (enableVideo != undefined) {
                      mediaHandler.setVideoEnabled(enableVideo);
                  }
              } else {
                  // Immediately enable/disable video tracks
                  if (enableVideo != undefined) {
                      mediaHandler.setVideoEnabled(enableVideo);
                  }
                  // Defer enabling/disabling audio tracks until tones have finished playing
                  DtmfPlayer.prependCallback(function () {
                      if (enableAudio != undefined) {
                          mediaHandler.setAudioEnabled(enableAudio);
                      }
                  });
              }
          };

          // Check if they are using the old method or new
          if (typeof enableVideo == "object") {
              console.debug("[" + that + "] - setLocalMediaEnabled() with: [screenshare: [" +
                  enableVideo.screenshare + "], audio: [" + enableVideo.audio + "], video: ["
                  + enableVideo.video + "]]");
              // If they are using the new object method, they may be doing screen sharing
              if (enableVideo.screenshare != undefined) {
                  if (enableVideo.screenshare) {
                      if (adapter.browserDetails.browser == "firefox") {
                          var error = "Screenshare not supported on Firefox";
                          console.debug("[" + that + "] - onGetScreenshareError() with [" + error + "]");
                          emitCallEvent("GetScreenshareError", error);
                          return;
                      }
                      swapToScreenshare();
                  } else {
                      swapToAudioVideo();
                  }
              } else {
                  handleAudioAndVideo(enableVideo.video, enableVideo.audio);
              }
          } else {
              console.debug("[" + that + "] - setLocalMediaEnabled() with: [audio: [" +
                  enableAudio + "], video: [" + enableVideo + "]]");
              handleAudioAndVideo(enableVideo, enableAudio);
          }
      };

      /**
       * Get the address of the remote party for the call.
       *
       * @returns {string} The external party address.
       */
      this.getRemoteAddress = function () {
          return remoteAddress;
      };

      /**
       * Indicates if the call has audio media streaming.
       *
       * @returns {boolean} Indicates if the call includes audio media streaming.
       */
      this.hasAudio = function () {
          return audio;
      };

      /**
       * Indicates if the call has video media streaming.
       *
       * @returns {boolean} Indicates if the call includes video media streaming.
       */
      this.hasVideo = function () {
          return video;
      };

      /**
       * Indicates if the call is held.
       *
       * @returns {boolean} Indicates if the call is held.
       */
      this.isHeld = function () {
          console.log("isHeld " + that.held);
          return that.held;
      };

      /**
       * Gets the length of time the call has been in progress.
       *
       * @returns {number} The call time elapsed in milliseconds.
       */
      this.getTimeElapsed = function () {
          if (callStartTime == 0) {
              return 0;
          }
          var currentTime = new Date();
          return currentTime - callStartTime;
      };

      /**
       * Gets the callID of the current call.
       *
       * @returns {string} The call ID of the call.
       */
      this.getCallId = function () {
          return callId;
      };

      /**
       * Places a call on hold.
       */
      this.hold = function () {
          console.debug("[" + that + "] - hold()");
          swiftSender.sendHold();
      };

      /**
       * Resumes a call on hold.
       */
      this.resume = function () {
          console.debug("[" + that + "] - resume()");
          swiftSender.sendResume();
      };

      /**
       * Gets the remote party display name of the current call.
       *
       * @returns {string} The external party display name.
       */
      this.getRemotePartyDisplayName = function () {
          return remoteDisplayName;
      };

      /**
       * Gets information about the local streams before muting,
       * so that they can be restored to their original state
       * afterwards. Information is returned as an array of
       * StreamInfo, each containing an id and an array of TrackInfo,
       * with each TrackInfo containing an id, enabled, and kind
       */
      var getStreamInfo = function () {
          var info = [];
          var streams;
          var stream;
          var tracks;
          var streamInfo;
          var trackInfo;

          // Process each local MediaStream
          streams = peerConnection.getLocalStreams();
          for (var iStream = 0; iStream < streams.length; iStream++) {
              stream = streams[iStream];
              // A new StreamInfo to save id and tracks
              streamInfo = new Object();
              streamInfo.id = stream.id;
              streamInfo.tracks = [];
              // Process each MediaStreamTrack in MediaStream
              tracks = stream.getAudioTracks();
              for (var iTrack = 0; iTrack < tracks.length; iTrack++) {
                  // A new TrackInfo,to save id, kind, and enabled
                  trackInfo = new Object();
                  trackInfo.enabled = tracks[iTrack].enabled;
                  trackInfo.id = tracks[iTrack].id;
                  trackInfo.kind = tracks[iTrack].kind;
                  // Save into StreamInfo
                  streamInfo.tracks[iTrack] = trackInfo;
              }
              // Save into info
              info[iStream] = streamInfo;
          }
          return info;
      };

      /**
       * Sets the enabled flag to false on all local audio streams.
       */
      var disableAudioStreams = function () {
          var streams;
          var info;
          var stream;
          var tracks;
          var track;

          // Process each local MediaStream
          streams = peerConnection.getLocalStreams();
          for (var iStream = 0; iStream < streams.length; iStream++) {
              stream = streams[iStream];
              // Process each MediaStreamTrack in stream
              tracks = stream.getAudioTracks();
              for (var iTrack = 0; iTrack < tracks.length; iTrack++) {
                  track = tracks[iTrack];
                  // Disable audio tracks - ignore all others
                  if (track.kind == "audio") {
                      track.enabled = false;
                  }
              }
          }
          return info;
      };

      /**
       * Restores the enabled value for each MediaStreamTrack in the
       * info object. The info object is an array of StreamInfo as
       * returned by getStreamInfo.
       */
      var restoreMediaStreams = function (info) {
          var streams;
          var streamInfo;
          var stream;
          var trackInfo;
          var track;

          // Process each MediaStream in info
          streams = peerConnection.getLocalStreams();
          for (var iInfo = 0; iInfo < info.length; iInfo++) {
              streamInfo = info[iInfo];
              // Get MediaStream for StreamInfo
              for (var iStream = 0; iStream < streams.length; iStream++) {
                  stream = streams[iStream];
                  if (stream.id === streamInfo.id) {
                      if (stream != null) {
                          // Process MediaStreamTrack in MediaStream
                          for (var iTrack = 0; iTrack < streamInfo.tracks.length; iTrack++) {
                              trackInfo = streamInfo.tracks[iTrack];
                              // Get MediaStreamTrack for TrackInfo
                              track = stream.getTrackById(trackInfo.id);
                              if (track != null) {
                                  // Set back to original enabled value
                                  track.enabled = trackInfo.enabled;
                              }
                          }
                      }
                  }
              }
          }
      };

      /**
       * Triggers a single or string of dtmf code(s) to be sent to the other end of the call.
       * Codes must be the character representations with 0-9, A-D, *, #, and comma being allowed.
       *
       * @param {string|number} dtmfCode  The code, or code string to send.
       * @param {boolean} playBack  True if the tone sound should be echoed back to the user.
       * @return {boolean} True on sending the code(s) to the server (this does not mean they were sent to the
       *                  other end of the call successfully), or false if an invalid code is included.
       */
      this.sendDtmf = function (dtmfCode, playBack) {
          console.debug("[" + that + "] - sendDtmf() with code: [" + dtmfCode + "], playback: [" + playBack + "]");

          var invalidCodes = new RegExp('[^0-9A-D\\*\\#\\,]', "g");

          if (typeof (dtmfCode) == "number") {
              if (dtmfCode < 0 || dtmfCode > 9) {
                  console.debug("[" + that + "] - sendDtmf() was not valid - returning false");
                  return false;
              }
          } else if (typeof (dtmfCode) == "string") {
              dtmfCode = dtmfCode.toUpperCase();
              if (invalidCodes.test(dtmfCode) == true) {
                  console.debug("[" + that + "] - sendDtmf() was not valid - returning false");
                  return false;
              }
          } else {
              console.debug("[" + that + "] - sendDtmf() was not valid - returning false");
              return false;
          }

          swiftSender.sendDTMF(dtmfCode);

          if (typeof (playBack) != "undefined" && playBack === true) {
              var tones = dtmfCode.split('');

              // Disable audio so that locally played tones don't leak into audio stream
              var streamInfo = getStreamInfo();
              disableAudioStreams();
              // Queue a callback to restore the media stream states
              DtmfPlayer.appendCallback(function () {
                  restoreMediaStreams(streamInfo);
              });
              // Play each tone in turn
              for (var i = 0; i < tones.length; i++) {
                  switch (tones[i]) {
                      //First set of tones that sound near enough the same
                      case '1':
                          DtmfPlayer.playToneLocally(697, 1209);
                          break;
                      case '4':
                          DtmfPlayer.playToneLocally(770, 1209);
                          break;
                      case '7':
                          DtmfPlayer.playToneLocally(852, 1209);
                          break;
                      case '*':
                          DtmfPlayer.playToneLocally(941, 1209);
                          break;

                      //Second set of tones that sound near enough the same
                      case '2':
                          DtmfPlayer.playToneLocally(697, 1336);
                          break;
                      case '5':
                          DtmfPlayer.playToneLocally(770, 1336);
                          break;
                      case '8':
                          DtmfPlayer.playToneLocally(852, 1336);
                          break;
                      case '0':
                          DtmfPlayer.playToneLocally(941, 1336);
                          break;

                      //Third set of tones that sound near enough the same
                      case '3':
                          DtmfPlayer.playToneLocally(697, 1477);
                          break;
                      case '6':
                          DtmfPlayer.playToneLocally(770, 1477);
                          break;
                      case '9':
                          DtmfPlayer.playToneLocally(852, 1477);
                          break;
                      case '#':
                          DtmfPlayer.playToneLocally(941, 1477);
                          break;

                      //Fourth set of tones that sound near enough the same
                      case 'A':
                          DtmfPlayer.playToneLocally(697, 1633);
                          break;
                      case 'B':
                          DtmfPlayer.playToneLocally(770, 1633);
                          break;
                      case 'C':
                          DtmfPlayer.playToneLocally(852, 1633);
                          break;
                      case 'D':
                          DtmfPlayer.playToneLocally(941, 1633);
                          break;

                      //The pause character - play no frequency for the duration of 2 tones
                      case ',':
                          DtmfPlayer.playToneLocally(0, 0);
                          DtmfPlayer.playToneLocally(0, 0);
                          DtmfPlayer.playToneLocally(0, 0);
                          DtmfPlayer.playToneLocally(0, 0);
                          DtmfPlayer.playToneLocally(0, 0);
                          DtmfPlayer.playToneLocally(0, 0);
                          break;

                      default:
                          console.log("Invalid tone: " + tones[i]);
                  }
              }
          }

          return true;
      };

      this.unsupportedGetPeerConnection = function () {
          console.debug("Unsupported access to underlying peer connection in use");
          return peerConnection;
      };

  }

  return phone;
}

module.exports = PCPhone;
