function createResolution(width, height) {
  return { width, height };
}

/**
 * Generate local media streams, the aim of this is to only request the local media once, there have been bugs in
 * chrome when requesting it multiple times, and it was considered highly annoying when using http and chrome
 * was prompting on every call.
 *
 * @class
 * @private
 */
function LocalMediaGenerator() {
    /**
     * List of allowable resolutions
     * @type {Object.<Resolution>}
     */
    var allowedResolutions = {
        videoCaptureResolution176x144: createResolution(176, 144),
        videoCaptureResolution352x288: createResolution(352, 288),
        videoCaptureResolution320x240: createResolution(320, 240),
        videoCaptureResolution640x480: createResolution(640, 480),
        videoCaptureResolution1280x720: createResolution(1280, 720),
        videoCaptureResolution1920x1080: createResolution(1920, 1080)
    };

    /**
     * Preferred video resolution
     * @type {Resolution}
     */
    var preferredCaptureResolution = allowedResolutions.videoCaptureResolution640x480;

    /**
     * Preferred video frame rate
     * @type {number}
     */
    var preferredCaptureFrameRate = 15;

    var preferredVideoInputId = "default";
    var preferredAudioInputId = "default";
    var preferredFacingVideo;

    /**
     * Gets allowable video resolutions for this device
     *
     * @returns {Object.<Resolution>}
     */
    this.getAllowedVideoResolutions = function () {
        return allowedResolutions;
    };

    /**
     * Gets the current preferred resolution
     * @returns {Resolution} the resolution
     */
    this.getPreferredCaptureResolution = function () {
        return preferredCaptureResolution;
    };

    /**
     * Gets the current preferred frame rate
     * @returns {number} the frame rate
     */
    this.getPreferredCaptureFrameRate = function () {
        return preferredCaptureFrameRate;
    };

    this.getPreferredCaptureFrameRate = function () {
        return preferredCaptureFrameRate;
    };

    /**
     * Gets the id of the current preferred video input source
     * @returns {string} the id of the device or "default" if no specific id has been set
     */
    this.getPreferredVideoInputId = function () {
        return preferredVideoInputId;
    };

    /**
     * Gets the current facing value
     * @returns {string} the currently set 'facing' camera, or undefined if not set.
     */
    this.getFacingVideo = function () {
        return preferredFacingVideo;
    };


    /**
     * Gets the id of the current preferred audio input source
     * @returns {string} the id of the device or "default" if no specific id has been set
     */
    this.getPreferredAudioInputId = function () {
        return preferredAudioInputId;
    };

    /**
     * Sets the preferred video resolution resolution - This should be set to a resolution from the
     * UC.phone.videoResolutions array.
     *
     * @param {Resolution} preferredResolution
     */
    this.setPreferredVideoCaptureResolution = function (preferredResolution) {
        preferredCaptureResolution = preferredResolution;
    };

    /**
     * Sets the preferred video frame rate, if this is too high it can cause the media capture to fail,
     * it is recommended to not set above 30
     *
     * @param {number} preferredFrameRate
     */
    this.setPreferredVideoCaptureFrameRate = function (preferredFrameRate) {
        preferredCaptureFrameRate = preferredFrameRate;
    };

    /**
     * Sets the id of the current preferred video input source
     * @param {string} id of the device or "default" for system default device
     */
    this.setPreferredVideoInputId = function (id) {
        preferredVideoInputId = id;
    };

    /**
     * Sets the id of the current preferred audio input source
     * @param {string} id of the device or "default" for system default device
     */
    this.setPreferredAudioInputId = function (id) {
        preferredAudioInputId = id;
    };

    /**
     * Sets the 'facing' video camera to use for mobile devices.
     * @param {string} facingVideo which camera to use
     */
        this.setFacingVideo = function(facingVideo) {
            preferredFacingVideo = facingVideo;
        };

    /**

        * Gets the enumerable list of input and output mediaDevices;
        * <p>
        * On success the list of devices is returned as an array of objects. The ids can be used with
        * setPreferredVideoInputDeviceId(), setPreferredAudioInputDeviceId(), setPreferredAudioOutputDeviceId(),
        * </p>
        *
        * @param {function} success function called with object array.
        */
    this.setOnGetMediaDevices = function (success) {
        console.log("getMediaDevices not defined for this type, exiting");
    }
}

LocalMediaGenerator.prototype = {
    /**
     * Stops the capture of your devices and releases the webcam
     * @param {function} onComplete callback called only if the capture device stops.
     */
    stopCapture: function (onComplete) {
        console.log("stopCapture not defined for this type, exiting");
    },

    /**
     * Get the local audio/video streams
     *
     * @param {function} success success callback
     * @param {function} failure failure callback
     */
    getAudioVideoStream: function (success, failure) {
        console.log("getAudioVideoStream not defined for this type, exiting");
    },

    /**
     * Get the local audio stream
     *
     * @param {function} success success callback
     * @param {function} failure failure callback
     */
    getAudioOnlyStream: function (success, failure) {
        console.log("getAudioOnlyStream not defined for this type, exiting");
    },

    /**
     * Switch both audio and video streams
     * @param {function} success success callback
     * @param {function} failure failure callback
     * @param {boolean} isPreview
     */
    switchAudioAndVideoStream: function(success, failure, isPreview) {
        console.log("switchAudioAndVideoStream not defined for this type, exiting");
    },

    /**
     * Switch the local video stream
     *
     * @param {function} success success callback
     * @param {function} failure failure callback
     * @param {boolean} isPreview
     */
    switchVideoOnlyStream: function (success, failure, isPreview) {
        console.log("switchVideoOnlyStream not defined for this type, exiting");
    },

    /**
     * Get the local video stream
     *
     * @param {function} success success callback
     * @param {function} failure failure callback
     */
    getVideoOnlyStream: function (success, failure) {
        console.log("getVideoOnlyStream not defined for this type, exiting");
    },

    /**
     * Get the fake audio stream
     *
     * @param {function} success success callback
     * @param {function} failure failure callback
     */
    getNoMediaStream: function (success, failure) {
            console.log("getNoMediaStream not defined for this type, exiting");
    },

    /**
     * Gets a local video only stream with the maximum resolution available
     * to the hardware. This default delegates to getVideoOnlyStream. Specific
     * media generators can define their own as necessary 
     * (see local-media-generator-chrome).
     */
    getMaxResolutionVideoOnlyStream: function (success, failure) {
        this.getVideoOnlyStream(success, failure);
    },

    /**
     * Gets a screenshare MediaStream object by passing appropriate constraints object to getUserMedia(...);
     * <p>
     * On success, the local screenshare variable is allocated the acquired MediaStream object, then audio tracks
     * from the existing AV MediaStream object are added to the screenshare object.
     * </p>
     *
     * @param {function} success function called upon successful creation of the screenshare MediaStream object.
     * @param {function} failure function called if screenshare creation unsuccessful.
     * @param {function} cancel function called if the user cancels the selecting of a screen to share
     */
    getScreenshare: function (success, failure, cancel) {
        console.log("getScreenshare not defined for this type, exiting");
    }
};

module.exports = LocalMediaGenerator;
