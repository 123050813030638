const utils = require('../common/utils.js');
/**
 * The control functions for BFCP on this call
 * @interface
 */
function BFCP(messageHandler, callId) {
  const self = {};
  let floorGranted = false;
  let closed = false;
  const emitEvent = utils.emitEvent.bind(null, self); // use self as source arg

  const sendBfcpFloorRequest = function () {
    var floorRequest = {
        "type": "FLOOR_CONTROL",
        "callId": callId,
        "content": {
            "FLOOR_REQUEST": {}
        }
    };
    messageHandler.sendSwiftMessage(floorRequest);
  }

  const sendBfcpFloorRelease = function () {
      var floorRelease = {
          "type": "FLOOR_CONTROL",
          "callId": callId,
          "content": {
              "FLOOR_RELEASE": {}
          }
      };
      messageHandler.sendSwiftMessage(floorRelease);
  }

  /*
    * Called when a floor control status message is received due to eg. failover.
    * This changes the state and sends a notification if the client's possession
    * of the floor has changed.
    * 
    * @private
    * @param {boolean} isConnected whether we are connected
    * @param {boolean} isFloorRequested whether we have requested the floor
    * @param {bbolean} isFloorGranted whether we have been granted the floor
    */
  const updateFloorControlState = function (isConnected, isFloorRequested, isFloorGranted) {
      if (isFloorGranted !== floorGranted) {
        floorGranted = isFloorGranted;
        if (isFloorGranted) {
          emitEvent("FloorRequestAccepted");
        }
        else {
          emitEvent("FloorControlEnded");
        }
      }
  };

  /**
   * Requests control of the floor in a conference controllable by BFCP. This can only be requested once a content stream is active
   */
  self.requestFloor = function () {
    console.debug("requestFloor()");
    sendBfcpFloorRequest();
  };

  /**
   * Releases the floor, this will not stop the content stream, which should be done using the methods for this on Call.
   */

  self.releaseFloor = function () {
      if (closed) {
          console.debug("Not sending release floor as call already ended");
      } else {
          console.debug("releaseFloor()");
          sendBfcpFloorRelease();
      }
      floorGranted = false;
  };

  self.handleFloorControl = function (jsonResult) {
    var content = jsonResult.content;
    if (content.FLOOR_REQUEST_ACCEPTED) {
      updateFloorControlState(true, false, true);
    } else if (content.FLOOR_CONTROL_ENDED) {
      updateFloorControlState(true, false, false);
    } else if (content.FLOOR_TAKEN) {
      var details = content.FLOOR_TAKEN;
      if (details.user || details.uri) {
        var user = { name: details.user, uri: details.uri };
        emitEvent("FloorTaken", user);
      } else {
        emitEvent("FloorTaken");
      }
    } else if (content.FLOOR_RELEASED) {
      emitEvent("FloorReleased");
    }
    else if (content.FLOOR_CONTROL_STATE) {
      updateFloorControlState(content.FLOOR_CONTROL_STATE.isConnected,
          content.FLOOR_CONTROL_STATE.isRequestingFloor,
          content.FLOOR_CONTROL_STATE.isGrantedFloor);
    }
  };

  self.close = function() {
    closed = true;
  }

  return self;
}

module.exports = BFCP;
