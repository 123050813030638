const LocalMediaGenerator = require('./local-media-generator.js');

var console = window.console;
var MediaStream = (window["webkitMediaStream"] || window["mozMediaStream"] || window.MediaStream);

//TODO Check why cookie saved Resolution is not being set on startup

/**
 * Generate local media streams, the aim of this is to only request the local media once, there have been bugs in
 * chrome when requesting it multiple times, and it was considered highly annoying when using http and chrome
 * was prompting on every call.
 *
 * @class
 * @extends LocalMediaGenerator
 */
function LocalMediaGeneratorBrowser() {

    console.log("Using LocalMediaGeneratorBrowser");
    var that = this;

    /**
     * The state of the streams
     *
     * @class
     * @private
     */
    var StreamState = {
        /**
         * Requested by the user
         */
        NOT_REQUESTED: 0,
        /**
         * In the process of gathering
         */
        GATHERING: 1,
        /**
         * We have got the stream
         */
        SUCCESSFUL: 2,
        /**
         * This stream failed
         */
        FAILED: 3,
        /**
         * The stream has been canceled
         */
        CANCELED: 4
    };

    var enumeratedDevicesUpdateFunction;
    var enumeratedDevices;
    var devicesNeedEnumerating = true;
    var multipleCallsWarning = false;

    var screenshareStreamGenerator = null;

    var onScreenCaptureSuccess = [];
    var onScreenCaptureFailure = [];


    //Creates and tracks stream clones
    var mediaStreamClone = function(stream){
        if(stream.clone) {

            var newStream = stream.clone();
            console.debug("Created clone ["
                + newStream.id +"] from stream ["
                + stream.id + "]");

            return newStream;
        } else {
            if(!multipleCallsWarning) {
                console.info("Multiple calls cannot be muted independently with this version of Firefox. Please update to version 48 or later to use this feature.");
                multipleCallsWarning = true;
            }
            console.debug("No clone - returning original [" + stream.id + "]");
            return stream;
        }
    };

    this.stopMediaStream = function(mediaStream) {
        if (!mediaStream) {
            console.debug("stopMediaStream: called with undefined stream");
            return;
        }
        console.debug("stopMediaStream: [" + mediaStream.id + "]");
        mediaStream.getTracks().forEach(function(track) {
            track.stop();
        });
    };

    function StreamGenerator() {

        var mediaStream = null;
        var mediaStreams = [];
        var mediaStreamState = StreamState.NOT_REQUESTED;
        var requestedConstraints = {};
        var mediaStreamQueue = [];
        var thisStreamGenerator = this;
        var constraintsAreTheSame = false;
        var onSuccess;

        var getConstraints = function (audio, video) {
            var constraints;

            if (audio) {
                if(that.getPreferredAudioInputId() === "default") {
                    constraints = {audio: true};
                } else {
                    constraints = {
                        audio: {deviceId: {exact: that.getPreferredAudioInputId()}}
                    };
                }
            } else {
                constraints = {audio: false};
            }

            if (video) {
                constraints["video"] = {
                    width: {exact: that.getPreferredCaptureResolution().width},
                    height: {exact: that.getPreferredCaptureResolution().height},
                    frameRate: {ideal: parseInt(that.getPreferredCaptureFrameRate())}
                };
                if (that.getFacingVideo() === "user") {
                    constraints.video.facingMode = "user";
                } else if (that.getFacingVideo() === "environment") {
                    constraints.video.facingMode = "environment";
                } else if (that.getPreferredVideoInputId() !== "default") {
                    constraints.video.deviceId = {exact: that.getPreferredVideoInputId()};
                }
            } else {
                constraints["video"] = false;
            }
            return constraints;
        };

        var getLowerResolutionConstraints = function (oldConstraints) {
            var currentWidth = oldConstraints.video.width.exact;
            if (oldConstraints.video && currentWidth && oldConstraints.video.height) {
                var allowedResolutions = that.getAllowedVideoResolutions();
                var biggestWidth = 0;
                var biggestWidthKey = null;
                Object.keys(allowedResolutions).forEach(function (key) {
                    if (allowedResolutions[key].width < currentWidth &&
                        allowedResolutions[key].width > biggestWidth) {
                        biggestWidth = allowedResolutions[key].width;
                        biggestWidthKey = key;
                    }
                });

                if (biggestWidthKey) {
                    var newConstraints = Object.assign({}, oldConstraints);
                    newConstraints.video.width.exact = biggestWidth;
                    newConstraints.video.height.exact = allowedResolutions[biggestWidthKey].height;
                    return newConstraints;
                }
            }
            return null;
        };

        var getHigherResolutionConstraints = function (oldConstraints) {
            var currentWidth = oldConstraints.video.width.exact;
            if (oldConstraints.video && currentWidth && oldConstraints.video.height) {
                var allowedResolutions = that.getAllowedVideoResolutions();
                var biggestWidth = 3840;
                var biggestWidthKey = null;
                Object.keys(allowedResolutions).forEach(function (key) {
                    if (allowedResolutions[key].width > currentWidth &&
                        allowedResolutions[key].width < biggestWidth) {
                        biggestWidth = allowedResolutions[key].width;
                        biggestWidthKey = key;
                    }
                });

                if (biggestWidthKey) {
                    var newConstraints = Object.assign({}, oldConstraints);
                    newConstraints.video.width.exact = biggestWidth;
                    newConstraints.video.height.exact = allowedResolutions[biggestWidthKey].height;
                    return newConstraints;
                }
            }

            return null;
        };

        var deepCompare = function(obj1, obj2) {
            var keys1 = Object.keys(obj1);
            var keys2 = Object.keys(obj2);

            if(keys1.length !== keys2.length) {
                return false;
            }

            for(var i = 0; i < keys1.length; i++) {
                if(keys2.indexOf(keys1[i]) === -1) {
                    return false;
                }

                if(typeof obj1[keys1[i]] !== typeof obj2[keys1[i]]) {
                    return false;
                }

                if(typeof obj1[keys1[i]] === "object") {
                    if(!deepCompare(obj1[keys1[i]], obj2[keys1[i]])) {
                        return false;
                    }
                } else if(obj1[keys1[i]] !== obj2[keys1[i]]) {
                    return false;
                }
            }

            return true;
        };

        var compareConstraints = function(constraints1, constraints2) {
            return(deepCompare(constraints1, constraints2));
        };

        var getCutDownStream = function (fullMediaStream, withAudio, withVideo) {
            var newMediaStream = new MediaStream;

            if (withAudio) {
                var existingAudio = fullMediaStream.getAudioTracks();
                for (var i = 0; i < existingAudio.length; i++) {
                    newMediaStream.addTrack(existingAudio[i]);
                }
            }

            if (withVideo) {
                var existingVideo = fullMediaStream.getVideoTracks();
                for (var j = 0; j < existingVideo.length; j++) {
                    newMediaStream.addTrack(existingVideo[j]);
                }
            }

            return newMediaStream;
        };

        function getFakeStream(ctx) {
            return ctx.createMediaStreamDestination().stream;
        }

        //TODO adjust this to look at aspect ratio too...
        function getMediaStreamForConstraints(requestConstraints, success, failure, searchDown) {
            console.debug("Trying constraints a=", requestConstraints.audio, ", v=", requestConstraints.video);
            var defaultGetUserMedia = navigator.mediaDevices.getUserMedia.bind(navigator.mediaDevices);
            if (typeof UC?.phone?._gum == "function") {
                // Use the virtual backgound implementation of getUserMedia
                defaultGetUserMedia = UC.phone._gum 
            }
        
            defaultGetUserMedia(requestConstraints)
                .then(function (stream) {

                    mediaStreams.push(stream);
                    console.debug("Generated a stream with id [" + stream.id + "]");

                    if (mediaStreamState !== StreamState.CANCELED) {
                        mediaStreamState = StreamState.SUCCESSFUL;
                        console.debug("Setting mediaStream as [" + stream.id + "]");
                        mediaStream = stream;
                    }
                    console.debug("User has granted access to local media.");
                    success(stream);
                })
                .catch(function (err) {
                    console.error("getMediaStreamForConstraints: produced error ", err);
                    if (err.name === "NotReadableError") {
                        // If request is not for a facingMode, do not continue to search.
                        if (that.getFacingVideo() !== "user" && that.getFacingVideo() !== "environment") {
                            console.debug("facingMode not set so not iterating through devices");
                            failure(err);
                            return;
                        }
                        // If user asked for a facingMode,
                        // Assume that the video device is unusable to generate a stream. (Ref CBALA-6)
                        // Iterate through devices until one is found that works.
                        for(;;) {
                            const deviceToTry = that.failedVideoDevices++;
                            const videoInputs = enumeratedDevices.videoInputs;
                            if (deviceToTry >= videoInputs.length) {
                                failure(err);
                                break;
                            } else {
                                var newConstraints = requestConstraints;
                                const vSource = videoInputs[deviceToTry];
                                newConstraints.video.deviceId = vSource.id;
                                console.debug("newConstraints.video=", newConstraints.video);
                                if (!setFacingModeConstraint(newConstraints, vSource, that.getFacingVideo())) {
                                    console.debug("Skipping");
                                    continue;
                                }
                                console.debug("newConstraints.video after setFacingModeConstraint=", newConstraints.video);
                                getMediaStreamForConstraints(newConstraints, success, failure, searchDown);
                                break;
                            }
                        }
                    } else if(!requestConstraints["audio"] && !requestConstraints["video"]) {
                        let ctx = new(window.AudioContext || window.webkitAudioContext);
                        let stream = getFakeStream(ctx);
                        success(stream);
                    } else {
                        if (requestConstraints["video"]) {
                            // "OverconstrainedError" is spec behaviour (Chrome / Firefox)
                            // and "Invalid constraint" is Safari native
                            // TODO check for correct "OverconstrainedError"s in future
                            if (err.name === "OverconstrainedError" || err.message === "Invalid constraint") {
                                let newConstraints = searchDown ? getLowerResolutionConstraints(requestConstraints) : getHigherResolutionConstraints(requestConstraints);
                                if (newConstraints) {
                                    getMediaStreamForConstraints(newConstraints, success, failure, searchDown);
                                } else {
                                    failure(err);
                                }
                            } else {
                                failure(err);
                            }
                        } else {
                            failure(err);
                        }
                    }
                });
            console.debug("Function end reached");
        }

        function setFacingModeConstraint(mediaConstraints, videoInput, constraintMode) {
            console.debug(mediaConstraints, videoInput, constraintMode);
            if (!mediaConstraints || !("video" in mediaConstraints) || !videoInput) {
                return false;
            }
            const label = "label" in videoInput ? videoInput["label"] : "";
            console.debug(label);
            switch (constraintMode) {
                case "user":
                    if (label.indexOf("front") !== -1) {
                        if ("facingMode" in mediaConstraints.video) {
                            delete mediaConstraints.video.facingMode;
                        }
                        return true;
                    }
                    if (label.indexOf("back") !== -1) {
                        return false;
                    }
                    mediaConstraints.video.facingMode = "user";
                    return true;
                case "environment":
                    if (label.indexOf("back") !== -1) {
                        if ("facingMode" in mediaConstraints.video) {
                            delete mediaConstraints.video.facingMode;
                        }
                        return true;
                    }
                    if (label.indexOf("front") !== -1) {
                        return false;
                    }
                    mediaConstraints.video.facingMode = "environment";
                    return true;
                default:
                    return true;
            }
        }

        this.addMediaStreamRequest = function(audio, video, success, failure, isPreview) {
            mediaStreamQueue.push(function() {
                thisStreamGenerator.getMediaStream(audio || isPreview, video, success, failure, isPreview);
            });
            if(mediaStreamQueue.length === 1) {
                mediaStreamQueue[0]();
            }
        };

        this.getMediaStream = function(audio, video, success, failure, isPreview) {

            //TODO clone streams for multiple calls

            var constraints;

            onSuccess = function(stream) {
                var newStream;
                var tempFunction;

                console.debug("onSuccess: with [" + stream.id + "]");

                requestedConstraints = constraints;
                console.debug("requestedConstraints set to:", JSON.stringify(requestedConstraints));

                if (mediaStream && mediaStream.id !== stream.id) {
                    console.debug("onSuccess: Replacing mediaStream [" + mediaStream.id + "] with [" + stream.id + "]");
                    var fnd = mediaStreams.find(function(ms) {
                        return ms.id === mediaStream.id;
                    } );
                    if (typeof fnd !== "undefined") {
                        console.log("onSuccess: Replaced mediaStream is already in mediaStreams");
                    } else {
                        console.log("onSuccess: Adding mediaStream [" + mediaStream.id + "] to mediaStreams ");
                        mediaStreams.push(mediaStream);
                    }
                } else {
                    console.debug("onSuccess: Nothing to replace for mediaStream")
                }

                mediaStream = stream;

                newStream = mediaStreamClone(mediaStream);
                mediaStreams.push(newStream);

                var newEnumeratedDevices = function(devices) {
                    console.debug("Enumerating devices");
                    that.failedVideoDevices = 0;

                    if(stream.getAudioTracks().length) {
                        var audioLabel = stream.getAudioTracks()[0]["label"];
                        console.debug("audioLabel=", audioLabel);
                    }

                    if (stream.getVideoTracks().length) {
                        var videoLabel = stream.getVideoTracks()[0].label;
                        console.debug("videoLabel=", videoLabel);
                    }

                    if (videoLabel && videoLabel.length && that.getPreferredVideoInputId() === "default") {
                        devices.videoInputs.forEach(function (input) {
                            if (input["label"] === videoLabel) {
                                that.setPreferredVideoInputId(input["id"]);
                            }
                        })
                    }

                    if (audioLabel && audioLabel.length && that.getPreferredAudioInputId() === "default") {
                        devices.audioInputs.forEach(function (input) {
                            if (input["label"] === audioLabel) {
                                that.setPreferredAudioInputId(input["id"]);
                            }
                        })
                    }

                    enumeratedDevicesUpdateFunction = tempFunction;
                    if(enumeratedDevicesUpdateFunction) {
                        enumeratedDevicesUpdateFunction(devices);
                    }
                };

                tempFunction = enumeratedDevicesUpdateFunction;
                that.setOnGetMediaDevices(newEnumeratedDevices);
                success(newStream);

                if(mediaStreamQueue.length) {
                    mediaStreamQueue.splice(0,1);
                    if(mediaStreamQueue.length) {
                        mediaStreamQueue[0]();
                    }
                }

            };

            var finalFailure = function(err) {
                if(mediaStreamQueue.length) {
                    mediaStreamQueue.splice(0,1);
                    if(mediaStreamQueue.length) {
                        mediaStreamQueue[0]();
                    }
                }
                failure(err);
            };

            var onFailure = function () {
                getMediaStreamForConstraints(constraints, onSuccess, finalFailure, false);
            };

            constraints = getConstraints(audio, video);

            // If facingMode is present, we may have had to add a video device ID on failure.
            // In this case, the presence of deviceId in requestedConstraints should be ignored.
            if (constraints.video && (typeof constraints.video === "object") && "facingMode" in constraints.video) {
                if (requestedConstraints.video && (typeof requestedConstraints.video === "object") && "deviceId" in requestedConstraints.video) {
                    delete requestedConstraints.video.deviceId;
                }
            }
            constraintsAreTheSame = compareConstraints(constraints, requestedConstraints);
            console.debug("constraints:", JSON.stringify(constraints));
            console.debug("requestedConstraints:", JSON.stringify(requestedConstraints));
            console.debug("constraintsAreTheSame:", constraintsAreTheSame);
            if (mediaStream) {
                console.debug("Comparing constraints for [" + mediaStream.id + "]");
            } else {
                console.debug("No current stream");
            }
            if(mediaStream && constraintsAreTheSame) {
                console.debug("Constraints are the same so cloning");
                var newStream = mediaStreamClone(mediaStream);
                mediaStreams.push(newStream);
                requestedConstraints = constraints;
                onSuccess(newStream);
            } else {
                console.debug("Constraints are different so stopping existing");
                this.stop();
                var tempSuccess = function(stream) {
                    onSuccess(stream);
                };
                console.debug("Requesting access to local media with mediaConstraints: [" + JSON.stringify(constraints) + "]");
                getMediaStreamForConstraints(constraints, tempSuccess, onFailure, true);
            }
        };

        this.getScreenshareMediaStream = function(screenshareConstraints, onScreenshareSuccess, onFailure) {
            navigator.mediaDevices.getUserMedia(screenshareConstraints).then(function (stream) {
                onScreenshareSuccess(stream)
            })["catch"](function (err) {
                onFailure(err)
            });
        };

        this.stop = function () {
            console.debug("stop: state is " + mediaStreamState);
            if (mediaStreamState !== StreamState.NOT_REQUESTED) {
                try {
                    console.debug("stop: Stopping Media Stream [" + mediaStream.id + "] and clones");
                    that.stopMediaStream(mediaStream);
                    console.debug("stop: nulling mediaStream [" + mediaStream.id + "]");
                    mediaStream = null;
                } catch (error) {
                    console.error("Error stopping stream [" + mediaStream.id + "] " + error);
                }

                while(mediaStreams.length) {
                    console.debug("Stopping associated stream [" + mediaStreams[0].id + "] and removing it.");
                    that.stopMediaStream(mediaStreams[0]);
                    mediaStreams.splice(0,1);
                }

                mediaStream = null;
                mediaStreamState = StreamState.NOT_REQUESTED;

                console.debug("stop: Stopped Media Stream and clones");
            }
        };
    }

    this.setOnGetMediaDevices = function (success) {
        if(devicesNeedEnumerating) {
            devicesNeedEnumerating = false;
            enumeratedDevices = {videoInputs: [], audioInputs: []};
            enumeratedDevicesUpdateFunction = success;

            var videoDefault = false;
            var audioDefault = false;
            var nMics = 1;
            var nCams = 1;
            // TODO On next Safari update ( > Tech Preview 11.1 r40) check re-implementing audio device selection
            navigator.mediaDevices.enumerateDevices()
                .then(function (devices) {
                    devices.forEach(function (device) {
                        if (device.kind === "videoinput") {
                            if (device.deviceId === "default" || device.label.includes("default") || device.label.includes("Default")) {
                                videoDefault = true;
                            }
                            enumeratedDevices.videoInputs.push({
                                label: device.label.length ? device.label : "Webcam " + nCams,
                                id: device.deviceId
                            });
                            nCams += 1;
                        } else if (device.kind === "audioinput") {
                            if (device.deviceId === "default" || device.label.includes("default") || device.label.includes("Default")) {
                                audioDefault = true;
                            }
                            enumeratedDevices.audioInputs.push({
                                label: device.label.length ? device.label : "Mic " + nMics,
                                id: device.deviceId
                            });
                            nMics += 1;
                        }
                        // This is an indication that getUserMedia has not been called so information will
                        // be incomplete (no labels)
                        if(!device.label.length) {
                            devicesNeedEnumerating = true;
                        }
                    });

                    if (!videoDefault && that.getPreferredVideoInputId() !== "default") {
                        enumeratedDevices.videoInputs.unshift({label: "Default", id: "default"});
                    }
                    if (!audioDefault && that.getPreferredAudioInputId() !== "default") {
                        enumeratedDevices.audioInputs.unshift({label: "Default", id: "default"});
                    }
                    success(enumeratedDevices);
                })
                .catch(function (err) {
                    console.log("unable to enumerate media devices");
                    console.log(err.name + ": " + err.message);
                });
        } else {
            enumeratedDevicesUpdateFunction = success;
            console.log(JSON.stringify(enumeratedDevices));
            success(enumeratedDevices);
        }
    };

    this.setDevicesNeedEnumerating = function (flag){
        devicesNeedEnumerating = flag;
    }

    var mediaStreamGenerator = new StreamGenerator();

    this.stopCapture = function(onComplete) {
        console.debug("stopCapture: ENTRY");
        mediaStreamGenerator.stop();

        if (onComplete) {
            onComplete();
        }

        console.debug("stopCapture: EXIT");
    };


    /**
     * Get the local audio/video streams
     *
     * @param {function} success success callback
     * @param {function} failure failure callback
     */
    this.getAudioVideoStream = function (success, failure) {
        mediaStreamGenerator.addMediaStreamRequest(true, true, success, failure, false);
    };

    /**
     * Get the local audio stream
     *
     * @param {function} success success callback
     * @param {function} failure failure callback
     */
    this.getAudioOnlyStream = function (success, failure) {
        mediaStreamGenerator.addMediaStreamRequest(true, false, success, failure, false);
    };

    /**
     * Get the fake stream
     *
     * @param {function} success success callback
     * @param {function} failure failure callback
     */
    this.getNoMediaStream = function (success, failure) {
        mediaStreamGenerator.addMediaStreamRequest(false, false, success, failure, false);
    };

    /**
     * Get the local video stream
     *
     * @param {function} success success callback
     * @param {function} failure failure callback
     */
    this.getVideoOnlyStream = function (success, failure, isPreview) {
        mediaStreamGenerator.addMediaStreamRequest(false, true, success, failure, isPreview);
    };

    /**
     * Switch both audio and video streams
     * @param {function} success success callback
     * @param {function} failure failure callback
     * @param {boolean} isPreview
     */
    this.switchAudioAndVideoStream = function(success, failure, isPreview) {
        that.stopCapture();
        mediaStreamGenerator.addMediaStreamRequest(true, true, success, failure, isPreview);
    };

    /**
     * Switch the local video stream
     *
     * @param {function} success success callback
     * @param {function} failure failure callback
     */
    this.switchVideoOnlyStream = function (success, failure, isPreview) {

        // First stop the existing streams
        that.stopCapture();

        mediaStreamGenerator.addMediaStreamRequest(false, true, success, failure, isPreview);
    };

    document.addEventListener("fcsdk-capture-screen-cancel", function () {
        console.log("User cancelled capture screen chooser");
        window.isScreenCaptureRequestInProgress = false;
        onScreenCaptureFailure.forEach(function (entity) {
            entity();
        });
        onScreenCaptureFailure = [];
        onScreenCaptureSuccess = [];
    });

    document.addEventListener("fcsdk-capture-screen-stream-id", function (event) {
        console.log("User choose screen stream id " + event.detail.streamId);
        window.isScreenCaptureRequestInProgress = false;
        onScreenCaptureSuccess.forEach(function (entity) {
            entity(event.detail.streamId);
        });
        onScreenCaptureFailure = [];
        onScreenCaptureSuccess = [];
    });

    var getScreenshareID = function (onSuccess, onFailure) {
        window.isScreenCaptureRequestInProgress = true;
        document.dispatchEvent(new Event('fcsdk-capture-screen'));
        onScreenCaptureSuccess.push(onSuccess);
        onScreenCaptureFailure.push(onFailure);
    };


    if(adapter.browserDetails.browser === "chrome") {
        /**
         * Gets a screenshare MediaStream object by passing appropriate constraints object to getUserMedia(...);
         * <p>
         * On success, the local screenshare variable is allocated the acquired MediaStream object, then audio tracks
         * from the existing AV MediaStream object are added to the screenshare object.
         * </p>
         *
         * @param {function} success function called upon successful creation of the screenshare MediaStream object.
         * @param {function} failure function called if screenshare creation unsuccessful.
         * @param {function} cancel function called if the user cancels the selecting of a screen to share
         */
        this.getScreenshare = function (success, failure, cancel) {
            var onIDSuccess = function (screenShareID) {
                var constraints = function () {
                    return {
                        audio: false,
                        video: {
                            mandatory: {
                                chromeMediaSource: "desktop",
                                chromeMediaSourceId: screenShareID,
                                minFrameRate: 1,
                                maxFrameRate: 5,
                                maxWidth: 1280,
                                maxHeight: 720//,
//                                googLeakyBucket: true,
//                                googTemporalLayeredScreencast: true
                            }
                        }
                    }
                };

                screenshareStreamGenerator = new StreamGenerator();

                var onFailure = function (error) {
                    console.error("Failed to get access to screen sharing with error: [" + error + "]");
                    failure(error);
                };

                var onScreenshareSuccess = function (stream) {
                    console.debug("User has granted access to screen sharing.");
                    success(stream);
                };

                screenshareStreamGenerator.getScreenshareMediaStream(constraints(), onScreenshareSuccess, onFailure);
            };

            var onIDFailure = function () {
                console.warn("The user cancelled the screen share request");
                cancel();
            };

            getScreenshareID(onIDSuccess, onIDFailure);
        };
    }
}

LocalMediaGeneratorBrowser.prototype = new LocalMediaGenerator;

module.exports = LocalMediaGeneratorBrowser;
