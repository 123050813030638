// @ts-check

/**
 * Analyse stats
 * 
 * @param {RTCStatsReport} stats 
 * @returns {{totalPacketsReceived: number, totalPacketsLost: number, 
 *           ssrcs: Map<string, number>}}}
 */
function packetCounter(stats) {
  let totalPacketsReceived = 0;
  let totalPacketsLost = 0;
  let ssrcs = new Map();

  stats.forEach((stat) => {
    if (stat.type === "inbound-rtp") {
      totalPacketsReceived += stat.packetsReceived;
      totalPacketsLost += stat.packetsLost;
      ssrcs.set(stat.ssrc, stat.packetsReceived);
    }
  });

  return { totalPacketsReceived, totalPacketsLost, ssrcs };
}

/**
 * factory function for Statistics object
 * @param {function(number): void} listener
 */
function Statistics(listener) {
  const self = {};
  let lastQuality = null;
  let lastLostTotal = 0;
  let lastReceivedTotal = 0;
  /** @type Map<string, number> */
  const lastReceivedBySsrc = new Map();

  /**
   * 
   * @param {number} quality 
   * @param {string} reason 
   */
  const updateQuality = function(quality, reason) {
    if (quality != lastQuality) {
      console.debug("Quality changed due to %s - new quality: %d",
                         reason, quality);
      listener(quality);
      lastQuality = quality;
    }
  }

  /**
   * processStats
   * @param {RTCStatsReport} report 
   * @param {string[]} activeSsrcs
   */
  self.processStats = function (report, activeSsrcs) {
    const packets = packetCounter(report);
    const packetsReceived = packets.totalPacketsReceived - lastReceivedTotal;
    const packetsLost = packets.totalPacketsLost - lastLostTotal;

    if (packetsReceived > 0 || packetsLost > 0) {
      const percentLost = packetsLost * 100 / (packetsReceived + packetsLost);
      const quality = Math.round(100 - (95 * percentLost / (percentLost + 10)));
      updateQuality(quality, "packet count");
      lastLostTotal = packets.totalPacketsLost;
      lastReceivedTotal = packets.totalPacketsReceived;
    }

    packets.ssrcs.forEach((packetCount, ssrc) => {
      if (activeSsrcs.includes(ssrc)) {
        if (lastReceivedBySsrc.has(ssrc)) {
          const ssrcPacketsReceived = packetCount - lastReceivedBySsrc.get(ssrc);
          if (ssrcPacketsReceived === 0) {
              updateQuality(0, "no media");
          }
        }
        lastReceivedBySsrc.set(ssrc, packetCount);
      }
    });
  };

  if (!listener) throw new Error("listener is required");
  return self;
}

module.exports = Statistics;