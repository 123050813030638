// @ts-check
var utils = require('./utils.js');
var emitEvent = utils.emitEvent;

/**
 * A representation of the AED object, this allows data sharing between users by creation of topics to which
 * data can be added.
 *
 * @interface
 */
function AED(messageHandler) {
  var topics = {};

  /**
   * Creates a new topic object to allow topic operations
   *
   * @param {string} topicName The topic name to create
   * @param {number} [timeout] How long, in minutes, the topic should be allowed to be left
   *            inactive before it expires and is deleted by the AED service.
   *            A value of 0 means that the topic will never expire. Negative
   *            numbers are not accepted. This value is optional - If not provided, the server
   *            default will be used.
   * @return {Topic} a Topic object for the named topic
   */
  var createTopic = function (topicName, timeout) {
    if (topics[topicName]) {
        return topics[topicName];
    }


    var createdTopic;

    if (timeout !== undefined) {
        var roundedTimeout = Math.round(timeout);
        if (!isNaN(roundedTimeout)) {
            createdTopic = new Topic(messageHandler, topicName, roundedTimeout);
        } else {
            createdTopic = new Topic(messageHandler, topicName);
        }

    } else {
        createdTopic = new Topic(messageHandler, topicName);
    }


    topics[topicName] = createdTopic;
    return createdTopic;
  };

  var handleTopicCreated = function (jsonResult) {
    topics[jsonResult.topic].connected = true;
    // Topic created, subscribe and get data
    var sendGetAllTopicData = {
        "type": "GET_ALL_TOPIC_DATA",
        "topic": jsonResult.topic
    };
    messageHandler.sendSwiftMessage(sendGetAllTopicData);

    var sendSubscribe = {
        "type": "SUBSCRIBE",
        "topic": jsonResult.topic
    };
    messageHandler.sendSwiftMessage(sendSubscribe);
  };

  var handleTopicData = function (jsonResult) {
    emitEvent(topics[jsonResult.topic], "ConnectSuccess", jsonResult.data.data);
  };

  var handleTopicDeleted = function (jsonResult) {
    emitEvent(topics[jsonResult.topic], "DeleteTopicSuccess", jsonResult.message);
  };

  var handlePublished = function (jsonResult) {
    emitEvent(topics[jsonResult.topic], "SubmitDataSuccess", jsonResult.key, jsonResult.value, jsonResult.version);
  };

  var handleUnpublished = function (jsonResult) {
    emitEvent(topics[jsonResult.topic], "DeleteDataSuccess", jsonResult.key, jsonResult.version);
  };

  var handleMessageSent = function (jsonResult) {
    emitEvent(topics[jsonResult.topic], "SendMessageSuccess", jsonResult.message);
  };

  var handleConnectError = function (jsonResult) {
    var topic = topics[jsonResult.topic];
    topic.connected = false;
    emitEvent(topic, "ConnectFailed", jsonResult.message);
  };

  var handleTopicDeleteError = function (jsonResult) {
    emitEvent(topics[jsonResult.topic], "DeleteTopicFailed", jsonResult.message);
  };

  var handlePublishError = function (jsonResult) {
    emitEvent(topics[jsonResult.topic], "SubmitDataFailed", jsonResult.key, jsonResult.value, jsonResult.message);
  };

  var handleUnpublishError = function (jsonResult) {
    emitEvent(topics[jsonResult.topic], "DeleteDataFailed", jsonResult.key, jsonResult.message);
  };

  var handleSendMessageError = function (jsonResult) {
    emitEvent(topics[jsonResult.topic], "SendMessageFailed", jsonResult.original_message, jsonResult.message);
  };

  var handleTopicExpired = function (jsonResult) {
    var topic = topics[jsonResult.topic];
    topic.connected = false;
    emitEvent(topic, "TopicDeleted");
  };

  var handleTopicUpdate = function (jsonResult) {
    var topic = topics[jsonResult.topic];
    if (topic.connected) {
      emitEvent(topic, "TopicUpdate", jsonResult.key, jsonResult.value, jsonResult.version, jsonResult.deleted);
    }
  };

  var handleTopicMessage = function (jsonResult) {
    var topic = topics[jsonResult.topic];
    if (topic.connected) {
      emitEvent(topic, "MessageReceived", jsonResult.message);
    }
  };

  messageHandler.addHandler("TOPIC_CREATED", handleTopicCreated);
  messageHandler.addHandler("TOPIC_CONFLICT", handleTopicCreated); // Treat CONFLICT as success
  messageHandler.addHandler("TOPIC_DELETED", handleTopicDeleted);
  messageHandler.addHandler("TOPIC_DATA", handleTopicData);
  messageHandler.addHandler("PUBLISHED", handlePublished);
  messageHandler.addHandler("UNPUBLISHED", handleUnpublished);
  messageHandler.addHandler("SUBSCRIBED", function() {});
  messageHandler.addHandler("SUBSCRIBE_CONFLICT", function() {}); // Treat CONFLICT as success
  messageHandler.addHandler("UNSUBSCRIBED", function() {});
  messageHandler.addHandler("MESSAGE_SENT", handleMessageSent);

  messageHandler.addHandler("TOPIC_CREATE_ERROR", handleConnectError);
  messageHandler.addHandler("TOPIC_DELETE_ERROR", handleTopicDeleteError);
  messageHandler.addHandler("TOPIC_DATA_ERROR", handleConnectError);
  messageHandler.addHandler("PUBLISH_ERROR", handlePublishError);
  messageHandler.addHandler("UNPUBLISH_ERROR", handleUnpublishError);
  messageHandler.addHandler("SUBSCRIBE_ERROR", function() {}); // Will only occur if TOPIC_DATA also fails
  messageHandler.addHandler("UNSUBSCRIBE_ERROR", function() {});
  messageHandler.addHandler("SEND_MESSAGE_ERROR", handleSendMessageError);

  messageHandler.addHandler("TOPIC_EXPIRED", handleTopicExpired);
  messageHandler.addHandler("TOPIC_UPDATE", handleTopicUpdate);
  messageHandler.addHandler("TOPIC_MESSAGE", handleTopicMessage);

  return { createTopic: createTopic }; // single public function
}

/**
 * A representation of a single Topic within AED. This object allows the
 * publishing and accessing of data within the topic, and provides callbacks
 * for request results and update notifications
 *
 * @interface
 * @param {object} messageHandler
 * @param {string} topicName
 * @param {number} [timeout]
 */
function Topic(messageHandler, topicName, timeout) {

  this.connected = false;

  /**
   * Returns the name of this topic.
   *
   * @returns {string} Topic name
   */
  this.getName = function () {
      return topicName;
  };

  /**
   * Returns whether this topic is connected or not. A topic is considered
   * connected if the connect() function has been called, and has not received
   * any of the following disconnection events: a disconnect() call, an
   * onConnectFailed event, or an onTopicDeleted event.
   *
   * @returns {boolean} true if the topic is connected as described above,
   *          false otherwise
   */
  this.isConnected = function () {
      return this.connected;
  };

  /**
   * Prepares the Topic object for use. If the AED topic this object controls
   * does not exist, it will be created with the supplied inactivity timeout.
   * If no timeout is specified, the default will be used.
   */
  this.connect = function () {
      var sendCreateTopic;

      if (timeout !== undefined) {
          sendCreateTopic = {
              "type": "CREATE_TOPIC",
              "topic": topicName,
              "timeout": timeout
          };
      } else {
          sendCreateTopic = {
              "type": "CREATE_TOPIC",
              "topic": topicName
          };
      }

      messageHandler.sendSwiftMessage(sendCreateTopic);
  };

  /**
   * Indicates to the server that the client is no longer interested in
   * publishing data to or receiving updates from this topic. No other
   * operations should be performed on this object without first calling
   * connect() again.
   *
   * @param {boolean} deleteTopic
   *            If true, the server will delete the topic completely. All data
   *            and subscriptions for that topic will be lost.
   */
  this.disconnect = function (deleteTopic) {
      var sendUnsubscribe = {
          "type": "UNSUBSCRIBE",
          "topic": topicName
      };
      messageHandler.sendSwiftMessage(sendUnsubscribe);

      if (deleteTopic === true) {
          var sendDeleteTopic = {
              "type": "DELETE_TOPIC",
              "topic": topicName
          };
          messageHandler.sendSwiftMessage(sendDeleteTopic);
      }
  };

  /**
   * Update topic data. If data attribute already exists its value will be
   * updated, otherwise a new data attribute will be created.
   *
   * @param {string} key
   *            The key of the data attribute to be updated
   * @param {string} value
   *            The new value of the data attribute
   */
  this.submitData = function (key, value) {
      var sendSubmitData = {
          "type": "PUBLISH",
          "topic": topicName,
          "key": key,
          "value": value
      };

      messageHandler.sendSwiftMessage(sendSubmitData);
  };

  /**
   * Delete specific data from a topic. All data versions for the attribute
   * will be deleted.
   *
   * @param {string} key
   *            The key of the data attribute to be updated
   */
  this.deleteData = function (key) {
      var sendDeleteData = {
          "type": "UNPUBLISH",
          "topic": topicName,
          "key": key
      };

      messageHandler.sendSwiftMessage(sendDeleteData);
  };

  /**
   * Send a message to all topic subscribers.
   *
   * @param {string} message_text The message text to send.
   */
  this.sendMessage = function (message_text) {
      var swiftMessage = {
          "type": "SEND_MESSAGE",
          "topic": topicName,
          "message": message_text
      };

      messageHandler.sendSwiftMessage(swiftMessage);
  };
}

module.exports = AED;