
function emitEvent(source, eventName) {
  // must work in IE11 so can't use rest syntax
  var args = Array.prototype.slice.call(arguments, 2);
  var handlerName = "on" + eventName;
  if (args.length == 0) {
    console.log('Issued event %s', eventName);
  } else {
    console.log('Issued event %s with args', eventName, args);
  }
  if (!source.hasOwnProperty(handlerName)) {
    return;
  }
  // call the handler with args and source as 'this'
  source[handlerName].apply(source, args);
}

module.exports = { emitEvent: emitEvent };
